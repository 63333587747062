import React, { useState, useEffect, useContext } from 'react';
import api from '../api'; // Pour envoyer des requêtes à l'API
import './Settings.css'; // Style spécifique aux settings
import Pricing from './Pricing'; // Importation du composant Pricing
import { AuthContext } from '../Login/AuthContext';

const Settings = ({ onOpenPricing }) => {

    const { subscription, setSubscription } = useContext(AuthContext)

    const [userInfo, setUserInfo] = useState({
        firstName: '',
        lastName: '',
        email: '',
    });

    const [passwords, setPasswords] = useState({
        newPassword: '',
        confirmNewPassword: '',
    });

    const [userSettings, setUserSettings] = useState({
        output_language: 'english',
        default_style: 'note',
        save_recordings: false
    });

    const [customStyles, setCustomStyles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds if it's in seconds
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    };

    const handleManageSubscription = async () => {

        try {
            const response = await api.get(`/stripe_dashboard`);
            window.location.href = response.data.dashboard_url;
        } catch (error) {
            console.error('Stripe:', error);
        }
    };



    // Récupère les infos de l'utilisateur via l'API
    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await api.get('/get_user_info');
                setUserInfo({
                    firstName: response.data.first_name || '',
                    lastName: response.data.last_name || '',
                    email: response.data.email || '',
                });
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };

        fetchUserInfo();
    }, []);

    // Gère la mise à jour des informations de compte
    const handleAccountInfoChange = (e) => {
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value,
        });
    };

    const updateAccountInfo = async () => {
        try {
            await api.post('/update_account_info', userInfo);
            alert('Account information updated successfully!');
        } catch (error) {
            console.error('Error updating account info:', error);
        }
    };

    // Gère la mise à jour du mot de passe
    const handlePasswordChange = (e) => {
        setPasswords({
            ...passwords,
            [e.target.name]: e.target.value,
        });
    };

    const updatePassword = async () => {
        if (passwords.newPassword !== passwords.confirmNewPassword) {
            alert('Passwords do not match.');
            return;
        }
        try {
            await api.post('/update_password', passwords);
            alert('Password updated successfully!');
        } catch (error) {
            console.error('Error updating password:', error);
        }
    };

    const handleSaveRecordingsChange = async (e) => {
        const newValue = e.target.checked;
        
        // Mise à jour immédiate de l'état local
        setUserSettings(prev => ({
            ...prev,
            save_recordings: newValue
        }));

        // Envoi de la requête au backend ensuite
        try {
            await api.post('/update_save_recordings', {
                save_recordings: newValue
            });
        } catch (error) {
            // En cas d'erreur, on revient à l'état précédent
            console.error('Error updating save recordings setting:', error);
            setUserSettings(prev => ({
                ...prev,
                save_recordings: !newValue
            }));
        }
    };

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const [settingsResponse, stylesResponse] = await Promise.all([
                    api.get('/get_user_settings'),
                    api.get('/custom_styles')
                ]);
                
                setUserSettings(settingsResponse.data);
                setCustomStyles(stylesResponse.data);
            } catch (error) {
                console.error('Error fetching settings:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchSettings();
    }, []);

    const saveUserSettings = async () => {
        try {
            await api.post('/update_user_settings', userSettings);
            alert('Settings updated successfully!');
        } catch (error) {
            console.error('Error updating settings:', error);
        }
    };

    return (
        <div className="settings-container">

<div className="panel setting-panel">
                <h3>User Settings</h3>
                <p>Configure your preferences.</p>
                
                <div className="form-group">
                    <label className="form-label" htmlFor="output_language">Output language</label>
                    <select
                        className="form-select"
                        name="output_language"
                        value={userSettings.output_language}
                        onChange={(e) => setUserSettings(prev => ({ ...prev, output_language: e.target.value }))}
                    >
                        <option value="afrikaans">Afrikaans 🇿🇦</option>
                        <option value="arabic">Arabic 🇸🇦</option>
                        <option value="armenian">Armenian 🇦🇲</option>
                        <option value="azerbaijani">Azerbaijani 🇦🇿</option>
                        <option value="belarusian">Belarusian 🇧🇾</option>
                        <option value="bosnian">Bosnian 🇧🇦</option>
                        <option value="bulgarian">Bulgarian 🇧🇬</option>
                        <option value="catalan">Catalan 🏴</option>
                        <option value="chinese">Chinese 🇨🇳</option>
                        <option value="croatian">Croatian 🇭🇷</option>
                        <option value="czech">Czech 🇨🇿</option>
                        <option value="danish">Danish 🇩🇰</option>
                        <option value="dutch">Dutch 🇳🇱</option>
                        <option value="english">English 🇬🇧</option>
                        <option value="estonian">Estonian 🇪🇪</option>
                        <option value="finnish">Finnish 🇫🇮</option>
                        <option value="french">French 🇫🇷</option>
                        <option value="galician">Galician 🏴</option>
                        <option value="german">German 🇩🇪</option>
                        <option value="greek">Greek 🇬🇷</option>
                        <option value="hebrew">Hebrew 🇮🇱</option>
                        <option value="hindi">Hindi 🇮🇳</option>
                        <option value="hungarian">Hungarian 🇭🇺</option>
                        <option value="icelandic">Icelandic 🇮🇸</option>
                        <option value="indonesian">Indonesian 🇮🇩</option>
                        <option value="italian">Italian 🇮🇹</option>
                        <option value="japanese">Japanese 🇯🇵</option>
                        <option value="kannada">Kannada 🇮🇳</option>
                        <option value="kazakh">Kazakh 🇰🇿</option>
                        <option value="korean">Korean 🇰🇷</option>
                        <option value="latvian">Latvian 🇱🇻</option>
                        <option value="lithuanian">Lithuanian 🇱🇹</option>
                        <option value="macedonian">Macedonian 🇲🇰</option>
                        <option value="malay">Malay 🇲🇾</option>
                        <option value="marathi">Marathi 🇮🇳</option>
                        <option value="maori">Maori 🇳🇿</option>
                        <option value="nepali">Nepali 🇳🇵</option>
                        <option value="norwegian">Norwegian 🇳🇴</option>
                        <option value="persian">Persian 🇮🇷</option>
                        <option value="polish">Polish 🇵🇱</option>
                        <option value="portuguese">Portuguese 🇵🇹</option>
                        <option value="romanian">Romanian 🇷🇴</option>
                        <option value="russian">Russian 🇷🇺</option>
                        <option value="serbian">Serbian 🇷🇸</option>
                        <option value="slovak">Slovak 🇸🇰</option>
                        <option value="slovenian">Slovenian 🇸🇮</option>
                        <option value="spanish">Spanish 🇪🇸</option>
                        <option value="swahili">Swahili 🇰🇪</option>
                        <option value="swedish">Swedish 🇸🇪</option>
                        <option value="tagalog">Tagalog 🇵🇭</option>
                        <option value="tamil">Tamil 🇮🇳</option>
                        <option value="thai">Thai 🇹🇭</option>
                        <option value="turkish">Turkish 🇹🇷</option>
                        <option value="ukrainian">Ukrainian 🇺🇦</option>
                        <option value="urdu">Urdu 🇵🇰</option>
                        <option value="vietnamese">Vietnamese 🇻🇳</option>
                        <option value="welsh">Welsh 🏴󠁧󠁢󠁷󠁬󠁳󠁿</option>
                    </select>
                </div>

                <div className="form-group">
                    <label className="form-label" htmlFor="default_style">Default note style</label>
                    <select
                        className="form-select"
                        name="default_style"
                        value={userSettings.default_style}
                        onChange={(e) => setUserSettings(prev => ({ ...prev, default_style: e.target.value }))}
                    >
                        <optgroup label="Built-in">
                            <option value="note">Note</option>
                            <option value="todolist">Todo List</option>
                            <option value="prompt">Prompt</option>
                            <option value="transcription">Transcription</option>
                        </optgroup>
                        <optgroup label="Custom">
                            {customStyles.map((style) => (
                                <option key={style.id} value={style.id}>{style.name}</option>
                            ))}
                        </optgroup>
                    </select>
                </div>

                {subscription && subscription.active && (
                    <div className="toggle-container">
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={userSettings.save_recordings}
                                onChange={handleSaveRecordingsChange}
                            />
                            <span className="toggle-slider"></span>
                        </label>
                        <div className="setting-option-label">
                            <span className="setting-option-title">Save recordings in library</span>
                        </div>
                    </div>
                )}

                <button className="button-plain" type="button" onClick={saveUserSettings}>
                    <span className="material-symbols-outlined icon">save</span> Save changes
                </button>
            </div>

            {/* Infos du compte */}
            <div className="panel setting-panel">
                <h3>Account Info</h3>
                <p>Update your account information.</p>
                <form>
                    <div className="form-group">
                        <label className="form-label" htmlFor="firstName">First name:</label>
                        <input
                            className="form-control"
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={userInfo.firstName}
                            onChange={handleAccountInfoChange}
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="lastName">Last name:</label>
                        <input
                            className="form-control"
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={userInfo.lastName}
                            onChange={handleAccountInfoChange}
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="email">Email:</label>
                        <input
                            className="form-control"
                            type="text"
                            id="email"
                            name="email"
                            value={userInfo.email}
                            readOnly
                            disabled
                            style={{ background: 'var(--gray-300)' }}
                        />
                    </div>
                    <button
                        type="button"
                        className="button-plain"
                        onClick={updateAccountInfo}
                    >
                        <span className="material-symbols-outlined icon">save</span> Save changes
                    </button>
                </form>
            </div>

            {/* Changement de mot de passe */}
            <div className="panel setting-panel">
                <h3>Update Password</h3>
                <p>Define or change your account's password.</p>
                <form>
                    <div className="form-group">
                        <label className="form-label" htmlFor="newPassword">New Password:</label>
                        <input
                            className="form-control"
                            type="password"
                            id="newPassword"
                            name="newPassword"
                            value={passwords.newPassword}
                            onChange={handlePasswordChange}
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="confirmNewPassword">New Password Confirmation:</label>
                        <input
                            className="form-control"
                            type="password"
                            id="confirmNewPassword"
                            name="confirmNewPassword"
                            value={passwords.confirmNewPassword}
                            onChange={handlePasswordChange}
                        />
                    </div>
                    <button
                        type="button"
                        className="button-plain"
                        onClick={updatePassword}
                    >
                        <span className="material-symbols-outlined icon">lock</span> Update Password
                    </button>
                </form>
            </div>

            {/* Gestion de l'abonnement */}
            <div className="panel setting-panel">
                <h3>Subscription</h3>
                <p>Manage your subscription.</p>

                {subscription && subscription.active ? (
                    <>
                        {subscription.cancelled ? (

                            <span className="subscription-state cancelled-subscription">
                                <span className="material-symbols-outlined icon">cancel</span> Echonote Plus | Cancelled : {formatDate(subscription.end_date)}
                            </span>

                        ) : (

                            <span className="subscription-state active-subscription">
                                <span className="material-symbols-outlined icon">check</span> Echonote Plus | Next payment : {formatDate(subscription.end_date)}
                            </span>

                        )}

                        <button
                            type="button"
                            className="button-plain"
                            onClick={handleManageSubscription}
                        >
                            <span className="material-symbols-outlined icon"><span class="material-symbols-outlined">
                                manage_accounts
                            </span></span> Manage subscription
                        </button>
                    </>
                ) : (
                    <>
                        <p>You are on the <strong>Free</strong> plan.</p>
                        <button className="button-important" onClick={onOpenPricing}>
                            <div className="inner">
                                <span className="material-symbols-outlined icon">star</span>
                                Upgrade to Echonote Plus
                            </div>
                        </button>

                        <p className="info-text">Plus can be used to make longer recordings and more. <a href="#" onClick={onOpenPricing}>More info here!</a></p>

                        
                    </>)}
            </div>

            {/* Gestion de l'account */}
            <div className="panel setting-panel">
                <h3>Account</h3>
                <p>Manage your account.</p>
                <button className="button-hollow" onClick={() => {
                    localStorage.removeItem('token');
                    window.location.reload();
                }}>
                    <span className="material-symbols-outlined icon">logout</span> Logout
                </button>
            </div>


        </div>
    );
};

export default Settings;
