import React, { useState, useEffect } from 'react';
import api from '../api';
import Popup from '../UI/Popup';
import { generateTagColor } from '../../utils/colorUtils';
import './AddTags.css';
import LoadingButton from '../UI/LoadingButton';

const AddTags = ({ onClose, selectedItems, onUpdate }) => {
    const [tags, setTags] = useState([]);
    const [selectedTagIds, setSelectedTagIds] = useState([]);
    const [isAdding, setIsAdding] = useState(false);

    useEffect(() => {
        fetchTags();
    }, []);

    const fetchTags = async () => {
        try {
            const response = await api.get('/tags');
            setTags(response.data);
        } catch (error) {
            console.error('Error retrieving tags:', error);
        }
    };

    const handleAddTags = async () => {
        try {
            setIsAdding(true);
            for (const item of selectedItems) {
                for (const tagId of selectedTagIds) {
                    if (item.type === 'note') {
                        await api.post(`/notes/${item.id}/tags`, { tag_id: tagId });
                    } else if (item.type === 'todolist') {
                        await api.post(`/todolists/${item.id}/tags`, { tag_id: tagId });
                    }
                }
            }
            onUpdate && onUpdate();
            onClose();
        } catch (error) {
            console.error('Error adding tags:', error);
        } finally {
            setIsAdding(false);
        }
    };

    return (
        <Popup onClose={onClose}>
            <div className="add-tags">
                <h2>Add Tags</h2>
                <div className="tags-selection">
                    {tags.map(tag => (
                        <div 
                            key={tag.id} 
                            className={`tag-option ${selectedTagIds.includes(tag.id) ? 'selected' : ''}`}
                            onClick={() => {
                                setSelectedTagIds(prev => 
                                    prev.includes(tag.id) 
                                        ? prev.filter(t => t !== tag.id)
                                        : [...prev, tag.id]
                                );
                            }}
                            style={{
                                backgroundColor: selectedTagIds.includes(tag.id) 
                                    ? generateTagColor(tag.name)
                                    : 'transparent',
                                borderColor: generateTagColor(tag.name),
                                color: selectedTagIds.includes(tag.id) ? 'white' : 'inherit'
                            }}
                        >
                            {tag.name}
                        </div>
                    ))}
                </div>
                <div className="actions">
                    <LoadingButton 
                        className="button-plain"
                        onClick={handleAddTags}
                        disabled={selectedTagIds.length === 0}
                        isLoading={isAdding}
                    >
                        <span className="material-symbols-outlined icon">add</span>
                        Add selected tags
                    </LoadingButton>
                </div>
            </div>
        </Popup>
    );
};

export default AddTags; 