import React, { useState, useEffect } from 'react';
import api from '../api';
import Popup from '../UI/Popup';
import LoadingButton from '../UI/LoadingButton';
import { generateTagColor } from '../../utils/colorUtils';
import './TagManager.css';

const TagManager = ({ onClose, onUpdate }) => {
    const [tags, setTags] = useState([]);
    const [newTagName, setNewTagName] = useState('');
    const [editingTag, setEditingTag] = useState(null);
    const [isCreating, setIsCreating] = useState(false);

    useEffect(() => {
        fetchTags();
    }, []);

    const fetchTags = async () => {
        try {
            const response = await api.get('/tags');
            setTags(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des tags:', error);
        }
    };

    const handleCreateTag = async () => {
        try {
            setIsCreating(true);
            await api.post('/tags', { name: newTagName });
            setNewTagName('');
            fetchTags();
            onUpdate?.();
        } catch (error) {
            console.error('Erreur lors de la création du tag:', error);
        } finally {
            setIsCreating(false);
        }
    };

    const handleUpdateTag = async (tagId) => {
        try {
            await api.put(`/tags/${tagId}`, { name: editingTag.name });
            setEditingTag(null);
            fetchTags();
            onUpdate?.();
        } catch (error) {
            console.error('Erreur lors de la mise à jour du tag:', error);
        }
    };

    const handleDeleteTag = async (tagId) => {
        try {
            await api.delete(`/tags/${tagId}`);
            fetchTags();
            onUpdate?.();
        } catch (error) {
            console.error('Erreur lors de la suppression du tag:', error);
        }
    };

    return (
        <Popup onClose={onClose}>
            <div className="tag-manager">
                <h2>Manage Tags</h2>
                
                <div className="create-tag">
                    <input
                        type="text"
                        value={newTagName}
                        onChange={(e) => setNewTagName(e.target.value)}
                        placeholder="New tag..."
                    />
                    <LoadingButton 
                        className="button-plain"
                        onClick={handleCreateTag}
                        disabled={!newTagName.trim()}
                        isLoading={isCreating}
                    >
                        <span className="material-symbols-outlined icon">add</span>
                        Create
                    </LoadingButton>
                </div>

                <div className="tags-list">
                    {tags.map(tag => (
                        <div key={tag.id} className="tag-item" style={{
                            backgroundColor: generateTagColor(tag.name),
                            color: 'white'
                        }}>
                            {editingTag?.id === tag.id ? (
                                <input
                                    type="text"
                                    value={editingTag.name}
                                    onChange={(e) => setEditingTag({...editingTag, name: e.target.value})}
                                    className="tag-edit-input"
                                />
                            ) : (
                                <span>{tag.name}</span>
                            )}
                            
                            <div className="tag-actions">
                                {editingTag?.id === tag.id ? (
                                    <button 
                                        className="button-icon"
                                        onClick={() => handleUpdateTag(tag.id)}
                                    >
                                        <span className="material-symbols-outlined">save</span>
                                    </button>
                                ) : (
                                    <button 
                                        className="button-icon"
                                        onClick={() => setEditingTag(tag)}
                                    >
                                        <span className="material-symbols-outlined">edit</span>
                                    </button>
                                )}
                                <button 
                                    className="button-icon"
                                    onClick={() => handleDeleteTag(tag.id)}
                                >
                                    <span className="material-symbols-outlined">delete</span>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Popup>
    );
};

export default TagManager; 