import React, { useState, useEffect, useContext } from 'react';
import WaveSurfer from 'wavesurfer.js';
import api from '../api';
import './Audio.css';
import LoadingButton from '../UI/LoadingButton';
import Popup from '../UI/Popup';
import { AuthContext } from '../Login/AuthContext';

const Audio = ({ onOpenPricing, onNoteCreated }) => {
    const [audios, setAudios] = useState([]);
    const [waveforms, setWaveforms] = useState({});
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentAudioId, setCurrentAudioId] = useState(null);
    const [loadingStates, setLoadingStates] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [isSelectionMode, setIsSelectionMode] = useState(false);
    const [selectedAudios, setSelectedAudios] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingAudio, setIsLoadingAudio] = useState(false);
    const [isStylePanelOpen, setIsStylePanelOpen] = useState(false);
    const [selectedAudio, setSelectedAudio] = useState(null);
    const [customStyles, setCustomStyles] = useState([]);
    const [selectedStyle, setSelectedStyle] = useState('');
    const { subscription } = useContext(AuthContext);

    useEffect(() => {
        const fetchCustomStyles = async () => {
            try {
                const response = await api.get('/custom_styles');
                setCustomStyles(response.data);
            } catch (error) {
                console.error('Error fetching custom styles:', error);
            }
        };
        fetchCustomStyles();
    }, []);

    // Fonction pour nettoyer les waveforms
    const cleanupWaveforms = () => {
        Object.values(waveforms).forEach(wavesurfer => {
            if (wavesurfer) {
                wavesurfer.destroy();
            }
        });
        setWaveforms({});
    };

    // Fetch initial des audios
    useEffect(() => {
        fetchAudios();
        return () => cleanupWaveforms();
    }, []);

    const fetchAudios = async () => {
        try {
            cleanupWaveforms();
            setAudios([]);
            const response = await api.get('/audios');
            setAudios(response.data);
        } catch (error) {
            console.error('Error fetching audios:', error);
        }
    };

    useEffect(() => {
        audios.forEach(audio => {
            const container = document.querySelector(`#waveform-${audio.id}`);
            if (!container) return;

            const wavesurfer = WaveSurfer.create({
                container: `#waveform-${audio.id}`,
                waveColor: '#999999',
                progressColor: '#1540ff',
                cursorColor: '#1f1fff',
                barWidth: 2,
                barRadius: 3,
                cursorWidth: 0,
                height: 40,
                barGap: 2,
                backend: 'WebAudio'
            });

            wavesurfer.on('ready', () => {
                console.log('WaveSurfer ready:', audio.id);
                setLoadingStates(prev => ({...prev, [audio.id]: false}));
                if (currentAudioId === audio.id && isPlaying) {
                    wavesurfer.play();
                }
            });

            wavesurfer.on('finish', function() {
                console.log('WaveSurfer finished:', audio.id);
                setIsPlaying(false);
                setCurrentAudioId(null);
            });

            wavesurfer.on('audioprocess', function() {
                const currentTime = wavesurfer.getCurrentTime();
                const duration = wavesurfer.getDuration();
                if (currentTime >= duration) {
                    console.log('Audio reached end:', audio.id);
                    setIsPlaying(false);
                    setCurrentAudioId(null);
                }
            });

            const loadAudio = async () => {
                setLoadingStates(prev => ({...prev, [audio.id]: true}));
                try {
                    const response = await api.get(`/audio/${audio.id}/stream`, {
                        responseType: 'blob'
                    });
                    const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
                    const audioUrl = URL.createObjectURL(audioBlob);
                    wavesurfer.load(audioUrl);
                } catch (error) {
                    console.error('Error loading audio:', error);
                    setLoadingStates(prev => ({...prev, [audio.id]: false}));
                }
            };

            loadAudio();
            setWaveforms(prev => ({...prev, [audio.id]: wavesurfer}));
        });
    }, [audios]);

    const handlePlay = (audioId) => {
        if (currentAudioId === audioId) {
            waveforms[audioId].playPause();
            setIsPlaying(!isPlaying);
        } else {
            if (currentAudioId && waveforms[currentAudioId]) {
                waveforms[currentAudioId].pause();
            }
            waveforms[audioId].play();
            setCurrentAudioId(audioId);
            setIsPlaying(true);
        }
    };

    const handleDeleteSelected = async () => {
        try {
            setIsLoading(true);
            
            // Arrêter la lecture si un audio sélectionné est en cours de lecture
            if (currentAudioId && selectedAudios.includes(currentAudioId)) {
                waveforms[currentAudioId]?.pause();
                setIsPlaying(false);
                setCurrentAudioId(null);
            }

            // Nettoyer les waveforms des audios qui vont être supprimés
            selectedAudios.forEach(audioId => {
                if (waveforms[audioId]) {
                    waveforms[audioId].destroy();
                    delete waveforms[audioId];
                }
            });

            // Supprimer les audios
            for (const audioId of selectedAudios) {
                await api.delete(`/audios/${audioId}`);
            }

            // Rafraîchir la liste complète des audios
            await fetchAudios();
            setSelectedAudios([]);
            setIsSelectionMode(false);
        } catch (error) {
            console.error('Error deleting audios:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const toggleSelectionMode = () => {
        setIsSelectionMode(!isSelectionMode);
        if (isSelectionMode) {
            setSelectedAudios([]);
        }
    };

    const handleSelectAudio = (e, audioId) => {
        e.stopPropagation();
        setSelectedAudios(prev =>
            prev.includes(audioId)
                ? prev.filter(id => id !== audioId)
                : [...prev, audioId]
        );
    };

    const formatDuration = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const filterAudios = (audios) => {
        if (!searchQuery.trim()) return audios;
        return audios.filter(audio => 
            audio.original_filename.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    const handleCreateNote = async (audioId, style) => {
        try {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('noteType', style);

            const response = await api.post(`/create_note_from_audio/${audioId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.success) {
                onNoteCreated(response.data.id, response.data.type);
            }
            setIsStylePanelOpen(false);
        } catch (error) {
            console.error('Error creating note:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const openStylePanel = (audioId) => {
        setSelectedAudio(audioId);
        setIsStylePanelOpen(true);
    };

    return (
        <div className="audio-section panel">
            <h1>Recordings</h1>
            {subscription && subscription.active ? (
                <>
                    <div className="audio-header">
                        
                        <div className="audio-actions">
                            <div className="toggle-container">
                                <label className="toggle-switch">
                                    <input
                                        type="checkbox"
                                        checked={isSelectionMode}
                                        onChange={toggleSelectionMode}
                                    />
                                    <span className="toggle-slider"></span>
                                </label>
                                <span className="toggle-label">Select recordings</span>
                            </div>

                            {isSelectionMode && (
                                <div className="audio-actions-buttons">
                                    <LoadingButton
                                        className="button-plain delete-button-desktop"
                                        onClick={handleDeleteSelected}
                                        disabled={selectedAudios.length === 0}
                                        isLoading={isLoading}
                                    >
                                        <span className="material-symbols-outlined icon">delete</span>
                                        Delete ({selectedAudios.length})
                                    </LoadingButton>

                                    <LoadingButton
                                        className="button-plain delete-button-mobile"
                                        onClick={handleDeleteSelected}
                                        disabled={selectedAudios.length === 0}
                                        isLoading={isLoading}
                                    >
                                        <span className="material-symbols-outlined icon">delete</span>
                                        ({selectedAudios.length})
                                    </LoadingButton>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="audio-container">
                        {filterAudios(audios).map(audio => (
                            <div
                                key={audio.id}
                                className={`audio-card ${isSelectionMode ? 'selection-mode' : ''}`}
                            >
                                {isSelectionMode && (
                                    <input
                                        type="checkbox"
                                        className="audio-checkbox"
                                        checked={selectedAudios.includes(audio.id)}
                                        onChange={(e) => handleSelectAudio(e, audio.id)}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                )}
                                <div className="audio-content">
                                    <button 
                                        className="button-icon" 
                                        onClick={() => !isSelectionMode && handlePlay(audio.id)}
                                        disabled={loadingStates[audio.id] || isSelectionMode}
                                    >
                                        <span className="material-symbols-outlined icon">
                                            {loadingStates[audio.id] 
                                                ? 'hourglass_empty' 
                                                : currentAudioId === audio.id && isPlaying 
                                                    ? 'pause' 
                                                    : 'play_arrow'
                                            }
                                        </span>
                                    </button>
                                    <div id={`waveform-${audio.id}`} className="waveform-container" />
                                </div>
                                <div className="audio-footer">
                                    <span className="audio-date">
                                        {new Date(audio.created_at).toLocaleDateString()}
                                    </span>
                                    <div className="audio-info">
                                        <button 
                                            className="audio-style-button"
                                            onClick={() => openStylePanel(audio.id)}
                                            disabled={isSelectionMode}
                                        >
                                            <span className="material-symbols-outlined icon">style</span>
                                            Convert
                                        </button>
                                        <span className="audio-category">
                                            <span className="material-symbols-outlined">graphic_eq</span>
                                            {formatDuration(audio.duration)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {isStylePanelOpen && (
                        <Popup onClose={() => setIsStylePanelOpen(false)}>
                            <div className="style-selection-panel">
                                <h3>Convert to Note</h3>
                                <select 
                                    className="form-select"
                                    onChange={(e) => setSelectedStyle(e.target.value)}
                                    defaultValue=""
                                >
                                    <option value="" disabled>Select a style</option>
                                    <optgroup label="Built-in">
                                        <option value="note">Note</option>
                                        <option value="todolist">Todo List</option>
                                        <option value="prompt">Prompt</option>
                                        <option value="transcription">Transcription</option>
                                    </optgroup>
                                    <optgroup label="Custom">
                                        {customStyles.map(style => (
                                            <option key={style.id} value={style.id}>
                                                {style.name}
                                            </option>
                                        ))}
                                    </optgroup>
                                </select>
                                <LoadingButton
                                    className="button-plain"
                                    onClick={() => handleCreateNote(selectedAudio, selectedStyle)}
                                    disabled={!selectedStyle}
                                    isLoading={isLoading}
                                >
                                    <span className="material-symbols-outlined icon">note_add</span>
                                    Create Note
                                </LoadingButton>
                            </div>
                        </Popup>
                    )}
                </>
            ) : (
                <div className='panel'>
                    <h2 style={{margin:'0'}}>Unlock Audio Library</h2>
                    <p>Upgrade now to save your recordings and access them anytime in your personal audio library.</p>
                    <button className="button-important" onClick={onOpenPricing}>
                        <div className="inner">
                            <span className="material-symbols-outlined icon">star</span>
                            Upgrade to Echonote Plus
                        </div>
                    </button>
                    <p style={{ marginTop:"15px", color:"#808080"}} className="info-text">
                        Plus can be used to make longer recordings and more. <a href="#" onClick={onOpenPricing}>More info here!</a>
                    </p>
                </div>
            )}
        </div>
    );
};

export default Audio; 