// components/Recorder.js
import React, { useState, useRef, useEffect, useContext } from 'react';
import Popup from '../UI/Popup'; // Importation du composant Popup pour la gestion des paramètres
import './Recorder.css';
import LoadingButton from '../UI/LoadingButton';
import api from '../api'; // Pour envoyer des données à l'API
import { AuthContext } from '../Login/AuthContext';

const Recorder = ({ onNoteCreated, onOpenPricing }) => {

    const { subscription, setSubscription } = useContext(AuthContext)

    const [isRecording, setIsRecording] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [chrono, setChrono] = useState('00:00');
    const [isCreateNoteDisabled, setIsCreateNoteDisabled] = useState(true);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [recordLimit, setRecordLimit] = useState(1 * 60 * 1000);

    const [isLoading, setIsLoading] = useState(false);
    const [uploadedAudio, setUploadedAudio] = useState(null);
    const [noteType, setNoteType] = useState('note');
    const [customStyles, setCustomStyles] = useState([]);

    const [isLoadingSettings, setIsLoadingSettings] = useState(true);
    const [userSettings, setUserSettings] = useState({
        output_language: 'english',
        default_style: 'note',
    });

    const mediaRecorderRef = useRef(null);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const canvasRef = useRef(null);
    const animationIdRef = useRef(null);
    const audioRef = useRef(null);
    const recordStartTime = useRef(0);
    const recordInterval = useRef(null);


    useEffect(() => {
        if (subscription && subscription.active) {
            setRecordLimit(20 * 60 * 1000); // 10 minutes for subscribers
        } else {
            setRecordLimit(1 * 60 * 1000); // 1 minute for non-subscribers
        }
    }, [subscription]);

    useEffect(() => {
        const fetchCustomStyles = async () => {
            try {
                const response = await api.get('/custom_styles');
                setCustomStyles(response.data);
            } catch (error) {
                console.error('Error fetching custom styles:', error);
            }
        };
        fetchCustomStyles();
    }, []);

    useEffect(() => {
        const fetchUserSettings = async () => {
            try {
                const response = await api.get('/get_user_settings');
                if (!response.data.error) {

                    setUserSettings({
                        output_language: response.data.output_language,
                        default_style: response.data.default_style,
                    });

                    setNoteType(response.data.default_style);
                }
            } catch (error) {
                console.error('Error fetching user settings:', error);
            } finally {
                setIsLoadingSettings(false);
            }
        };
        fetchUserSettings();
    }, []);


    useEffect(() => {
        initializeCanvas();
    }, []);

    const saveUserSettings = async () => {
        try {
            const response = await api.post('/update_user_settings', {
                output_language: userSettings.output_language,
                default_style: userSettings.default_style,
            });
            if (response.data.result === 'OK') {
                console.log('User settings updated successfully');
            } else {
                console.error('Failed to update user settings');
            }
        } catch (error) {
            console.error('Error updating user settings:', error);
        }
    };

    const initializeCanvas = () => {
        const canvas = canvasRef.current;
        const canvasCtx = canvas.getContext('2d');

        // Effacer et préparer le canvas
        canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
        canvasCtx.fillStyle = "#ffffff00";
        canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

        canvasCtx.lineWidth = 2;
        canvasCtx.strokeStyle = '#1540ff';
        canvasCtx.beginPath();
        canvasCtx.moveTo(0, canvas.height / 2);
        canvasCtx.lineTo(canvas.width, canvas.height / 2);
        canvasCtx.stroke();
    };

    const startRecording = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        analyserRef.current = audioContextRef.current.createAnalyser();
        const source = audioContextRef.current.createMediaStreamSource(stream);
        source.connect(analyserRef.current);

        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.start();

        recordStartTime.current = Date.now();
        recordInterval.current = setInterval(updateRecordingTime, 1000);

        mediaRecorderRef.current.ondataavailable = (event) => {
            const url = URL.createObjectURL(event.data);
            setAudioUrl(url);
            setIsCreateNoteDisabled(false); // Activer "Create note" après enregistrement
        };

        setIsRecording(true);
        visualize();
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.ondataavailable = (event) => {
                const blob = event.data;
                setAudioUrl(URL.createObjectURL(blob));
                setUploadedAudio(blob); // Enregistre le blob pour l'upload
                setIsCreateNoteDisabled(false); // Active le bouton Create Note après l'enregistrement
            };
            mediaRecorderRef.current.stop();
            clearInterval(recordInterval.current);
            mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());

            setIsRecording(false);
            cancelAnimationFrame(animationIdRef.current);
            if (audioContextRef.current) audioContextRef.current.close();
        }
    };


    const updateRecordingTime = () => {
        const elapsedTime = Date.now() - recordStartTime.current;
        const minutes = Math.floor(elapsedTime / 60000);
        const seconds = Math.floor((elapsedTime % 60000) / 1000);
        setChrono(`${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`);

        if (elapsedTime >= recordLimit) {
            stopRecording();
        }
    };

    const playReplay = () => {
        if (audioUrl) {
            audioRef.current = new Audio(audioUrl);
            audioRef.current.play();
            setIsPlaying(true);

            audioRef.current.onended = () => {
                setIsPlaying(false);
            };
        }
    };

    const stopReplay = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setIsPlaying(false);
        }
    };

    const resetRecorder = () => {
        stopReplay();
        setAudioUrl(null);
        setUploadedAudio(null);
        setChrono('00:00');
        setIsCreateNoteDisabled(true);
        initializeCanvas();  // Réinitialiser le canvas
    };

    const visualize = () => {
        const canvas = canvasRef.current;
        const canvasCtx = canvas.getContext('2d');
        analyserRef.current.fftSize = 2048;
        const bufferLength = analyserRef.current.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const draw = () => {
            animationIdRef.current = requestAnimationFrame(draw);
            analyserRef.current.getByteTimeDomainData(dataArray);

            canvasCtx.fillStyle = 'white';
            canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

            canvasCtx.lineWidth = 2;
            canvasCtx.strokeStyle = '#1540ff';
            canvasCtx.beginPath();

            const sliceWidth = (canvas.width * 1.0) / bufferLength;
            let x = 0;

            for (let i = 0; i < bufferLength; i++) {
                const v = dataArray[i] / 128.0;
                const y = (v * canvas.height) / 2;

                if (i === 0) {
                    canvasCtx.moveTo(x, y);
                } else {
                    canvasCtx.lineTo(x, y);
                }

                x += sliceWidth;
            }

            canvasCtx.lineTo(canvas.width, canvas.height / 2);
            canvasCtx.stroke();
        };

        draw();
    };

    // Gestion de l'upload audio
    const handleAudioUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = function (e) {
                const blob = new Blob([new Uint8Array(e.target.result)], { type: file.type });
                setUploadedAudio(blob); // Enregistrer le blob audio uploadé
                setAudioUrl(URL.createObjectURL(blob)); // Générer l'URL pour la prévisualisation
                setIsCreateNoteDisabled(false); // Activer "Create note" après l'upload
            };
            reader.readAsArrayBuffer(file);

            // Cacher les boutons de record
            setIsRecording(false);
        }
    };

    const sendAudio = async () => {
        setIsLoading(true);
        setIsCreateNoteDisabled(true);

        const formData = new FormData();
        const guid = crypto.randomUUID();
        formData.append('audio', uploadedAudio || new Blob([mediaRecorderRef.current], { type: 'audio/mp3' }), `${guid}.mp3`);
        formData.append('noteType', noteType); // Prendre en compte le type de note sélectionné

        try {
            const response = await api.post('/create_note', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Note created:', response.data);
            resetRecorder();
            onNoteCreated(response.data.id, response.data.type);

        } catch (error) {
            console.error('Error creating note:', error);
        } finally {
            setIsLoading(false);
        }
    };



    return (
        <div className="recorder">

            {subscription && subscription.active ? (
                <div className="upload-button">

                    <button class="button-important" onClick={() => document.getElementById('audioUpload').click()}>
                        <div className='inner'>
                            <span class="material-symbols-outlined icon">upload_file</span>
                            Upload audio
                        </div>
                    </button>
                    <input
                        id="audioUpload"
                        type="file"
                        accept="audio/*"
                        onChange={handleAudioUpload}
                        style={{ display: 'none' }}
                    />
                </div>
            ) : (
                <div className="upload-button">
                    <button class="button-important" onClick={onOpenPricing}>
                        <div className='inner'>
                            <span class="material-symbols-outlined icon">star</span>
                            Upload audio
                        </div>
                    </button>
                </div>
            )}
            <div className='recorder-container'>
                <div className="panel recorder-panel">
                    <canvas className="audio-spectrum" ref={canvasRef}></canvas>
                    <div className="time-display">
                        <span className="chrono">{chrono}</span>
                        <span className="time-limit">Limit: {recordLimit / 60000}:00</span>
                    </div>
                    <div className="record-buttons">
                        {audioUrl && !isPlaying && (
                            <button className="button-control" onClick={playReplay}>
                                <span className="material-symbols-outlined icon">play_arrow</span>
                            </button>
                        )}
                        {isPlaying && (
                            <button className="button-control" onClick={stopReplay}>
                                <span className="material-symbols-outlined icon">stop</span>
                            </button>
                        )}
                        <button
                            className={`button-record ${isRecording ? 'breathing-effect' : ''}`}
                            onClick={isRecording ? stopRecording : startRecording}
                        >
                            {isRecording ? (
                                <span className="material-symbols-outlined icon">stop</span>
                            ) : (
                                <span className="material-symbols-outlined icon">mic</span>
                            )}
                        </button>
                        {audioUrl && (
                            <button className="button-control" onClick={resetRecorder}>
                                <span className="material-symbols-outlined icon">delete</span>
                            </button>
                        )}
                    </div>
                    {!isLoadingSettings && (
                        <button className="button-hollow" onClick={() => setIsSettingsOpen(true)}>
                            <span className="material-symbols-outlined icon">settings</span> Settings
                        </button>)}
                    <select
                        id="note-type"
                        name="note_type"
                        value={noteType}
                        onChange={(e) => setNoteType(e.target.value)} // Met à jour le type de note
                        style={{ marginTop: '10px', marginBottom: '20px' }}
                    >
                        <optgroup label="Built-in">
                            <option value="note">Note</option>
                            <option value="todolist">Todo List</option>
                            <option value="prompt">Prompt</option>
                            <option value="transcription">Transcription</option>
                        </optgroup>
                        <optgroup label="Custom">
                            {customStyles.map((style) => (
                                <option key={style.id} value={style.id}>{style.name}</option>
                            ))}
                        </optgroup>
                    </select>

                    <LoadingButton
                        isLoading={isLoading}
                        disabled={isCreateNoteDisabled}
                        onClick={sendAudio}
                    >
                        <span className="material-symbols-outlined icon">add_circle</span>
                        Create note
                    </LoadingButton>
                </div>
            </div>

            {isSettingsOpen && (
                <Popup onClose={() => setIsSettingsOpen(false)}>
                    <div className="recorder-settings-panel">
                        <h3>Recording Preferences</h3>
                        <p>Set up your recording preferences</p>
                        <form>
                            <div className="form-group">
                                <label className="form-label" htmlFor="output_language">Output language</label>
                                <select
                                    className="form-select"
                                    name="output_language"
                                    value={userSettings.output_language}
                                    onChange={(e) => setUserSettings((prev) => ({ ...prev, output_language: e.target.value }))}
                                >
                                    <option value="afrikaans">Afrikaans 🇿🇦</option>
                                    <option value="albanian">Albanian 🇦🇱</option>
                                    <option value="amharic">Amharic 🇪🇹</option>
                                    <option value="arabic">Arabic 🇸🇦</option>
                                    <option value="armenian">Armenian 🇦🇲</option>
                                    <option value="azerbaijani">Azerbaijani 🇦🇿</option>
                                    <option value="basque">Basque 🏴</option>
                                    <option value="belarusian">Belarusian 🇧🇾</option>
                                    <option value="bengali">Bengali 🇧🇩</option>
                                    <option value="bosnian">Bosnian 🇧🇦</option>
                                    <option value="bulgarian">Bulgarian 🇧🇬</option>
                                    <option value="catalan">Catalan 🏴</option>
                                    <option value="cebuano">Cebuano 🇵🇭</option>
                                    <option value="chinese">Chinese 🇨🇳</option>
                                    <option value="corsican">Corsican 🇫🇷</option>
                                    <option value="croatian">Croatian 🇭🇷</option>
                                    <option value="czech">Czech 🇨🇿</option>
                                    <option value="danish">Danish 🇩🇰</option>
                                    <option value="dutch">Dutch 🇳🇱</option>
                                    <option value="english">English 🇬🇧</option>
                                    <option value="esperanto">Esperanto 🌍</option>
                                    <option value="estonian">Estonian 🇪🇪</option>
                                    <option value="filipino">Filipino 🇵🇭</option>
                                    <option value="finnish">Finnish 🇫🇮</option>
                                    <option value="french">French 🇫🇷</option>
                                    <option value="frisian">Frisian 🇳🇱</option>
                                    <option value="galician">Galician 🏴</option>
                                    <option value="georgian">Georgian 🇬🇪</option>
                                    <option value="german">German 🇩🇪</option>
                                    <option value="greek">Greek 🇬🇷</option>
                                    <option value="gujarati">Gujarati 🇮🇳</option>
                                    <option value="haitian">Haitian Creole 🇭🇹</option>
                                    <option value="hausa">Hausa 🇳🇬</option>
                                    <option value="hawaiian">Hawaiian 🌺</option>
                                    <option value="hebrew">Hebrew 🇮🇱</option>
                                    <option value="hindi">Hindi 🇮🇳</option>
                                    <option value="hmong">Hmong 🌏</option>
                                    <option value="hungarian">Hungarian 🇭🇺</option>
                                    <option value="icelandic">Icelandic 🇮🇸</option>
                                    <option value="igbo">Igbo 🇳🇬</option>
                                    <option value="indonesian">Indonesian 🇮🇩</option>
                                    <option value="irish">Irish 🇮🇪</option>
                                    <option value="italian">Italian 🇮🇹</option>
                                    <option value="japanese">Japanese 🇯🇵</option>
                                    <option value="javanese">Javanese 🇮🇩</option>
                                    <option value="kannada">Kannada 🇮🇳</option>
                                    <option value="kazakh">Kazakh 🇰🇿</option>
                                    <option value="khmer">Khmer 🇰🇭</option>
                                    <option value="korean">Korean 🇰🇷</option>
                                    <option value="kurdish">Kurdish 🏴</option>
                                    <option value="kyrgyz">Kyrgyz 🇰🇬</option>
                                    <option value="lao">Lao 🇱🇦</option>
                                    <option value="latin">Latin 🏛️</option>
                                    <option value="latvian">Latvian 🇱🇻</option>
                                    <option value="lithuanian">Lithuanian 🇱🇹</option>
                                    <option value="luxembourgish">Luxembourgish 🇱🇺</option>
                                    <option value="macedonian">Macedonian 🇲🇰</option>
                                    <option value="malagasy">Malagasy 🇲🇬</option>
                                    <option value="malay">Malay 🇲🇾</option>
                                    <option value="malayalam">Malayalam 🇮🇳</option>
                                    <option value="maltese">Maltese 🇲🇹</option>
                                    <option value="maori">Maori 🇳🇿</option>
                                    <option value="marathi">Marathi 🇮🇳</option>
                                    <option value="mongolian">Mongolian 🇲🇳</option>
                                    <option value="myanmar">Myanmar (Burmese) 🇲🇲</option>
                                    <option value="nepali">Nepali 🇳🇵</option>
                                    <option value="norwegian">Norwegian 🇳🇴</option>
                                    <option value="odia">Odia 🇮🇳</option>
                                    <option value="pashto">Pashto 🇦🇫</option>
                                    <option value="persian">Persian 🇮🇷</option>
                                    <option value="polish">Polish 🇵🇱</option>
                                    <option value="portuguese">Portuguese 🇵🇹</option>
                                    <option value="punjabi">Punjabi 🇮🇳</option>
                                    <option value="romanian">Romanian 🇷🇴</option>
                                    <option value="russian">Russian 🇷🇺</option>
                                    <option value="samoan">Samoan 🇼🇸</option>
                                    <option value="scots">Scots Gaelic 🏴󠁧󠁢󠁳󠁣󠁴󠁿</option>
                                    <option value="serbian">Serbian 🇷🇸</option>
                                    <option value="sesotho">Sesotho 🇱🇸</option>
                                    <option value="shona">Shona 🇿🇼</option>
                                    <option value="sindhi">Sindhi 🇵🇰</option>
                                    <option value="sinhala">Sinhala 🇱🇰</option>
                                    <option value="slovak">Slovak 🇸🇰</option>
                                    <option value="slovenian">Slovenian 🇸🇮</option>
                                    <option value="somali">Somali 🇸🇴</option>
                                    <option value="spanish">Spanish 🇪🇸</option>
                                    <option value="sundanese">Sundanese 🇮🇩</option>
                                    <option value="swahili">Swahili 🇰🇪</option>
                                    <option value="swedish">Swedish 🇸🇪</option>
                                    <option value="tajik">Tajik 🇹🇯</option>
                                    <option value="tamil">Tamil 🇮🇳</option>
                                    <option value="telugu">Telugu 🇮🇳</option>
                                    <option value="thai">Thai 🇹🇭</option>
                                    <option value="turkish">Turkish 🇹🇷</option>
                                    <option value="ukrainian">Ukrainian 🇺🇦</option>
                                    <option value="urdu">Urdu 🇵🇰</option>
                                    <option value="uyghur">Uyghur 🇨🇳</option>
                                    <option value="uzbek">Uzbek 🇺🇿</option>
                                    <option value="vietnamese">Vietnamese 🇻🇳</option>
                                    <option value="welsh">Welsh 🏴󠁧󠁢󠁷󠁬󠁳󠁿</option>
                                    <option value="xhosa">Xhosa 🇿🇦</option>
                                    <option value="yiddish">Yiddish 🕎</option>
                                    <option value="yoruba">Yoruba 🇳🇬</option>
                                    <option value="zulu">Zulu 🇿🇦</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="default_style">Default note style</label>
                                <select
                                    className="form-select"
                                    name="default_style"
                                    value={userSettings.default_style}
                                    onChange={(e) => setUserSettings((prev) => ({ ...prev, default_style: e.target.value }))}
                                >
                                    <optgroup label="Built-in">
                                        <option value="note">Note</option>
                                        <option value="todolist">Todo List</option>
                                        <option value="prompt">Prompt</option>
                                        <option value="transcription">Transcription</option>
                                    </optgroup>
                                    <optgroup label="Custom">
                                        {customStyles.map((style) => (
                                            <option key={style.id} value={style.id}>{style.name}</option>
                                        ))}
                                    </optgroup>
                                </select>
                            </div>
                            <button className="button-plain" type="button" onClick={() => {
                                saveUserSettings();
                                setIsSettingsOpen(false);
                            }}>
                                <span className="material-symbols-outlined icon">save</span> Save changes
                            </button>
                        </form>
                    </div>
                </Popup>
            )}

        </div>
    );
};

export default Recorder;
