import React from 'react';
import './Footer.css'; // Assurez-vous d'avoir un fichier CSS pour le style


const Footer = () => {
    return (
        <footer className="footer">
            <div className="container section">
                <div className="footer-row">
                    <div className='footer-col'>
                        <h3>Our Softwares</h3>
                        <ul>
                            <li><a href="https://ytcopycat.com" target="_blank" rel="noopener noreferrer">YT Copycat</a></li>
                            <li><a href="https://bookwitch.io" target="_blank" rel="noopener noreferrer">Book Witch</a></li>
                            <li><a href="https://compress.boundlesscognition.com" target="_blank" rel="noopener noreferrer">Free Image Compressor</a></li>
                        </ul>
                    </div>
                    <div className="footer-col">
                        <h3>Tools</h3>
                        <ul>
                            <li><a href="/tools/transcribe-to-text/mp3">Transcribe MP3 to Text</a></li>
                        </ul>
                    </div>
                    <div className="footer-col">
                        <h3>About</h3>
                        <ul>
                            <li><a href="/legal" target="_blank" rel="noopener noreferrer">Term of use</a></li>
                            <li><a href="/legal" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
                        </ul>
                    </div>
                    <div className="footer-contact">
                        <img src="/logo_light.png" alt="Echonote Logo" className="footer-logo" />
                        <p>Contact us at <a href="mailto:contact@echonote.xyz">contact@echonote.xyz</a></p>
                    </div>
                </div>
                <hr />
                <div className="footer-bottom">
                    <p>Copyright © 2024 Echonote</p>
                </div>

            </div>
        </footer>
    );
};

export default Footer; 