// Fonction pour générer une couleur HSL à partir d'une chaîne
export const generateTagColor = (tagName) => {
    // Calcul du hash de la chaîne
    let hash = 0;
    for (let i = 0; i < tagName.length; i++) {
        hash = tagName.charCodeAt(i) + ((hash << 5) - hash);
    }
    
    // Conversion en HSL
    const hue = hash % 360;
    // Saturation fixe à 65% pour des couleurs vives mais pas trop
    const saturation = 65;
    // Luminosité fixe à 45% pour assurer un bon contraste avec le texte blanc
    const lightness = 45;
    
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}; 