// components/Popup.js
import React, { useEffect, useRef } from 'react';
import './Popup.css';

const Popup = ({ children, onClose }) => {
    const popupRef = useRef(null);

    const handleOverlayClick = (e) => {
        if (e.target.classList.contains('popup-overlay')) {
            onClose(false);
        }
    };

    return (
        <div className="popup-overlay" onClick={handleOverlayClick}>
            <div className="popup-content" ref={popupRef}>
                <button className="close-button button-plain" onClick={onClose}>
                    <span className="material-symbols-outlined">close</span>
                </button>
                {children}
            </div>
        </div>
    );
};

export default Popup;
