import React, { useState } from 'react';
import Popup from '../UI/Popup'; // Assure-toi que ce composant Popup existe dans ton projet
import api from '../api'; // Pour envoyer les requêtes à l'API
import './Pricing.css'; // Fichier CSS spécifique pour ce composant
import LoadingButton from '../UI/LoadingButton';

const Pricing = ({ onClose }) => {
    const [selectedPlan, setSelectedPlan] = useState(process.env.REACT_APP_ANNUAL_PRICE_ID);
    const [checkoutLoading, setCheckoutLoading] = useState(false);
    const [showBenefits, setShowBenefits] = useState(true); // Afficher les bénéfices d'abord

    const plans = [
        { id: process.env.REACT_APP_ANNUAL_PRICE_ID, price: '$62.65 / year', description: '= $3.9/month, billed annually', oldPrice: '$179', save: '65%' },
        { id: process.env.REACT_APP_MONTH_PRICE_ID, price: '$8.22 / month', description: '', oldPrice: '$14.95', save: '45%' }
    ];

    const handlePlanSelection = (id) => {
        setSelectedPlan(id);
    };

    const handleSubmit = async () => {
        try {
            setCheckoutLoading(true);
            const response = await api.post(`/checkout/${selectedPlan}`);
            window.location.href = response.data.checkout_url;
            setCheckoutLoading(false);
        } catch (error) {
            console.error('Error during checkout:', error);
            setCheckoutLoading(false);
        }
    };

    const handleNext = () => {
        setShowBenefits(false); // Affiche le panneau des prix après les bénéfices
    };

    return (
        <Popup onClose={onClose}>
            <div className="choose-plan-container">
                {showBenefits ? (
                    <div className="benefits-panel">
                        <h2 className="choose-plan-title">Unlock Echonote Plus</h2>
                        <span className="limited-offer-text">Use Echonote without limitations</span>

                        <div className="benefit">
                            <span className="material-symbols-outlined icon">all_inclusive</span>
                            <div>
                                <strong>Unlimited Note Creation</strong>
                                <p>Record and create unlimited notes, with extended recording time of up to 20 minutes per note.</p>
                            </div>
                        </div>

                        <div className="benefit">
                            <span className="material-symbols-outlined icon">label</span>
                            <div>
                                <strong>Advanced Organization</strong>
                                <p>Create, edit and manage custom tags to organize your notes efficiently.</p>
                            </div>
                        </div>

                        <div className="benefit">
                            <span className="material-symbols-outlined icon">palette</span>
                            <div>
                                <strong>Advanced Customization</strong>
                                <p>Unlock a variety of new styles and personalize your notes with custom prompts and themes!</p>
                            </div>
                        </div>

                        <div className="benefit">
                            <span className="material-symbols-outlined icon">upload_file</span>
                            <div>
                                <strong>Upload Your Own Files</strong>
                                <p>Convert your personal audio files into notes, with support for multiple formats!</p>
                            </div>
                        </div>

                        <div className="benefit">
                            <span className="material-symbols-outlined icon">library_music</span>
                            <div>
                                <strong>Audio Library</strong>
                                <p>Save and reuse your recordings anytime. Build your personal audio library for future reference!</p>
                            </div>
                        </div>

                        <div class="benefit">
                            <span class="material-symbols-outlined icon">update</span>
                            <div class="benefit-text">
                                <strong>Future Updates</strong>
                                <p>Enjoy regular feature updates and improvements based on your feedback.</p>
                            </div>
                        </div>

                        <button className="button-plain" onClick={handleNext}>
                            <span className="material-symbols-outlined icon">arrow_forward</span> Next step
                        </button>
                    </div>
                ) : (
                    <div className="pricing-panel">
                        <h2 className="choose-plan-title">Choose your plan</h2>
                        <p className="limited-offer-text">Limited Launch Offer:</p>
                        <h3 className="secure-offer-text">Secure up to 65% OFF</h3>

                        <div className="plan-options">
                            {plans.map((plan) => (
                                <div
                                    key={plan.id}
                                    className={`plan-option ${selectedPlan === plan.id ? 'selected' : ''}`}
                                    onClick={() => handlePlanSelection(plan.id)}
                                >
                                    {plan.save && <div className="plan-save">Save {plan.save}</div>}
                                    <div className="plan-pricing">
                                        <del className="old-price">{plan.oldPrice}</del>
                                        <span className="plan-price">{plan.price}</span>
                                    </div>
                                    <div className="plan-description">{plan.description}</div>
                                </div>
                            ))}
                        </div>

                        <LoadingButton
                            isLoading={checkoutLoading}
                            onClick={handleSubmit}
                        >
                            <span className="material-symbols-outlined icon">star</span>
                            Get Echonote Plus
                        </LoadingButton>

                        <p className="cancel-text">Cancel anytime</p>
                    </div>
                )}
            </div>
        </Popup>
    );
};

export default Pricing;
