// BlogPost.js
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navbar from '../Page/Navbar';

import './Blog.css'

const BlogPost = () => {
    const { slug } = useParams();
    const [article, setArticle] = useState(null);

    useEffect(() => {
        // Charger les données de l'article en fonction du slug
        fetch(`/articles/${slug}.json`)
            .then((response) => {
                if (response.ok) return response.json();
                throw new Error('Article not found');
            })
            .then((data) => setArticle(data))
            .catch((error) => setArticle(null));
    }, [slug]);

    function formatDate(timestamp) {
        const date = new Date(timestamp * 1000); // Multiplie par 1000 pour convertir en millisecondes
        return new Intl.DateTimeFormat('en-GB', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        }).format(date);
    }

    if (!article) {
        return <p>Loading</p>;
    }

    return (
        <>
            <Helmet>
                <title>{article.title} - My App</title>
                <meta name="description" content={article.description} />

                {/* Open Graph Meta Tags */}
                <meta property="og:title" content={article.title} />
                <meta property="og:description" content={article.description} />
                <meta property="og:type" content="article" />
                <meta property="og:url" content={`https://echonote.xyz/blog/${slug}`} />
                <meta property="og:image" content={`https://echonote.xyz/images/${slug}.jpg`} />

                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={article.title} />
                <meta name="twitter:description" content={article.description} />
                <meta name="twitter:image" content={`https://echonote.xyz/images/${slug}.jpg`} />

            </Helmet>
            <Navbar />

            <div className='article'>

                <span className='path'><Link to={"/"}>Home</Link> &gt; <Link to={"/blog"}>Blog</Link> &gt; {article.slug} </span>
                
                <h1>{article.title}</h1>

                <div className='pub-info'>
                <span>3 min read</span>
                <span>Published : {formatDate(article.date)}</span>
                </div>

                <hr/>

                <img src={`/images/${article.slug}.jpg`} />
                
                <div dangerouslySetInnerHTML={{ __html: article.content }} />
            </div>
        </>
    );
};

export default BlogPost;
