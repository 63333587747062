// Sidebar.js
import React, { useContext } from 'react';
import './Sidebar.css'
import { Link } from 'react-router-dom';
import { AuthContext } from '../Login/AuthContext';

const Sidebar = ({ onSelect, selected, onOpenPricing }) => {
    const { subscription } = useContext(AuthContext);

    return (
        <div className="side-menu">
            <img className='logo' src="/logo_light.png" alt="Logo" style={{ width: '70%', marginBottom: '30px' }} />
            <div className="nav">
                
                <button className="button-important" onClick={() => onSelect('record')}>
                    <div className="inner">
                        <span className="material-symbols-outlined icon">mic</span>
                        Create a note
                    </div>
                </button>
                <button
                    className={`button-sidemenu ${selected === 'notes' ? 'active' : ''}`}
                    onClick={() => onSelect('notes')}
                >
                    <span className="material-symbols-outlined icon">note</span>
                    Notes
                </button>
                <button
                    className={`button-sidemenu ${selected === 'audio' ? 'active' : ''}`}
                    onClick={() => onSelect('audio')}
                >
                    <span className="material-symbols-outlined icon">graphic_eq</span>
                    Recordings
                </button>
                <button
                    className={`button-sidemenu ${selected === 'custom' ? 'active' : ''}`}
                    onClick={() => onSelect('custom')}
                >
                    <span className="material-symbols-outlined icon">brush</span>
                    Custom Styles
                </button>
                <button
                    className={`button-sidemenu ${selected === 'settings' ? 'active' : ''}`}
                    onClick={() => onSelect('settings')}
                >
                    <span className="material-symbols-outlined icon">settings</span>
                    Settings
                </button>
            </div>

            {!subscription?.active && (
                <div className="premium-cta">
                    <h3>Unlock Plus Features</h3>
                    <p>Create custom styles, manage tags, and make longer recordings.</p>
                    <button className="button-important" onClick={onOpenPricing}>
                        <div className="inner">
                            <span className="material-symbols-outlined icon">star</span>
                            Upgrade Now
                        </div>
                    </button>
                </div>
            )}

            <div className="useful-links">
                <button className="button-plain" onClick={() => window.open("https://echonote.canny.io", "_blank")}>
                <span className="material-symbols-outlined icon">feedback</span>
                Give feedback
                </button>
            </div>
        </div>
    );
};

export default Sidebar;
