import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../api';

const LoginWithToken = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Extraire le token des paramètres de l'URL
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    useEffect(() => {
        const verifyToken = async () => {
            try {
                const response = await api.get(`/login_with_token/${token}`);
                localStorage.setItem('token', response.data.access_token);

                
                
                //navigate('/app'); 
                window.location.href = '/app';
            } catch (error) {
                console.error('Login error:', error);
                navigate('/login');  
            }
        };

        if (token) {
            verifyToken();
        } else {
            navigate('/login');
        }
    }, [token, navigate]);

    return <div>Connecting...</div>;
};

export default LoginWithToken;
