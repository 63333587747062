// App.js
import React, { useState, useContext } from 'react';
import Sidebar from './components/App/Sidebar';
import BottomMenu from './components/App/BottomMenu';
import Recorder from './components/App/Recorder';
import Notes from './components/App/Notes';
import Settings from './components/App/Settings';
import CustomStyle from './components/App/CustomStyle';
import Note from './components/App/Note';
import TodoList from './components/App/Todolist';
import Audio from './components/App/Audio';
import { AuthContext } from './components/Login/AuthContext';

import './App.css';
import Pricing from './components/App/Pricing';

function App() {
    // État pour suivre la section active et les ID pour Note et TodoList
    const [activeSection, setActiveSection] = useState('record');
    const [selectedNoteId, setSelectedNoteId] = useState(null);
    const [selectedTodolistId, setSelectedTodolistId] = useState(null);
    const { isAuthenticated } = useContext(AuthContext);

    const [isPricingOpen, setIsPricingOpen] = useState(false);

    // Fonction pour changer de section
    const handleSectionChange = (section) => {
        setActiveSection(section);
        setSelectedNoteId(null);
        setSelectedTodolistId(null);
    };

    const handleNoteCreated = (id, type) => {

        if (type == "todolist") {
            handleOpenTodolist(id);
        }
        else {
            handleOpenNote(id);
        }
    };

    // Fonction pour ouvrir une note
    const handleOpenNote = (id) => {
        setSelectedNoteId(id);
        setActiveSection("note");
    };

    // Fonction pour ouvrir une todolist
    const handleOpenTodolist = (id) => {
        setSelectedTodolistId(id);
        setActiveSection("todolist");
    };

    // Composant à afficher en fonction de la section active
    const renderActiveSection = () => {
        switch (activeSection) {
            case 'record':
                return <Recorder onNoteCreated={handleNoteCreated} onOpenPricing={() => {setIsPricingOpen(true)}}/>;
            case 'notes':
                return <Notes onOpenNote={handleOpenNote} onOpenTodolist={handleOpenTodolist} onOpenPricing={() => setIsPricingOpen(true)} />;
            case 'audio':
                return <Audio onOpenPricing={() => setIsPricingOpen(true)} onNoteCreated={handleNoteCreated} />;
            case 'settings':
                return <Settings onOpenPricing={() => {setIsPricingOpen(true)}}/>;
            case 'custom':
                return <CustomStyle onOpenPricing={() => {setIsPricingOpen(true)}}/>;
            case 'note':
                return selectedNoteId ? <Note noteId={selectedNoteId} onBack={() => handleSectionChange('notes')} /> : <Notes onOpenNote={handleOpenNote} onOpenTodolist={handleOpenTodolist} onOpenPricing={() => setIsPricingOpen(true)} />;
            case 'todolist':
                return selectedTodolistId ? <TodoList todolistId={selectedTodolistId} onBack={() => handleSectionChange('notes')} /> : <Notes onOpenNote={handleOpenNote} onOpenTodolist={handleOpenTodolist} onOpenPricing={() => setIsPricingOpen(true)} />;
            default:
                return <Recorder />;
        }
    };

    return (
        <>
            <div className="container-fluid" style={{ padding: 0 }}>
                {isAuthenticated && (
                    <div className='app'>
                        <Sidebar 
                            onSelect={handleSectionChange} 
                            selected={activeSection}
                            onOpenPricing={() => setIsPricingOpen(true)}
                        />

                        <div className="content-section">
                            {renderActiveSection()}
                        </div>
                        <BottomMenu onSelect={handleSectionChange} selected={activeSection} />
                    </div>
                )}
            </div>

            {isPricingOpen && (
                <Pricing onClose={() => setIsPricingOpen(false)} />
            )}
        </>
    );
}

export default App;
