// components/CustomStyle.js
import React, { useContext, useEffect, useState } from 'react';
import CustomStyleForm from './CustomStyleForm'; // Assurez-vous d'avoir importé le composant CustomStyleForm pour la popup
import api from '../api';
import './CustomStyle.css';
import { AuthContext } from '../Login/AuthContext';

const builtInStyles = [
    {
        id: 1,
        name: 'Note',
        description: 'A cleaned up & organized version of your audio. Keep the exact content but remove the "hum", add punctuation, etc.',
        icon: 'description' // Google Material icon for Note
    },
    {
        id: 2,
        name: 'Transcript',
        description: 'Clean transcription of your audio. Automatically remove blank and hesitation.',
        icon: 'article' // Google Material icon for Transcript
    },
    {
        id: 3,
        name: 'To-Do List',
        description: 'Checkable and editable To-do list. Get clear tasks.',
        icon: 'checklist' // Google Material icon for To-Do List
    },
    {
        id: 4,
        name: 'Prompt',
        description: 'Interpret your audio as a full prompt. Ideal for generating structured responses from your input.',
        icon: 'chat' // Google Material icon for Prompt
    }
];

const CustomStyle = ({onOpenPricing}) => {
    const [customStyles, setCustomStyles] = useState([]);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [currentStyle, setCurrentStyle] = useState(null);

    const { subscription, setSubscription } = useContext(AuthContext)

    useEffect(() => {
        const fetchCustomStyles = async () => {
            try {
                const response = await api.get('/custom_styles');
                setCustomStyles(response.data);
            } catch (error) {
                console.error('Error fetching custom styles:', error);
            }
        };
        fetchCustomStyles();
    }, []);

    const openForm = (style = null) => {
        setCurrentStyle(style);
        setIsFormOpen(true);
    };

    const closeForm = () => {
        setIsFormOpen(false);
        setCurrentStyle(null);
    };

    return (
        <div className="panel custom-style-section">
            <h1>Styles</h1>

            {subscription && subscription.active ? (
                <>
                    <h2>Custom</h2>
                    <p>Create your own style, write your own instruction to get personalized notes</p>
                    <button className="button-plain" onClick={() => openForm()}>
                        <span className="material-symbols-outlined">add_circle</span> Create custom style
                    </button>
                    <div className="custom-style-container">
                        {customStyles.map((style) => (
                            <div
                                className="custom-style-card"
                                key={style.id}
                                onClick={() => openForm(style)}
                            >
                                <div className="custom-style-icon">
                                    <span className="material-symbols-outlined icon">edit</span>
                                </div>
                                <div className="custom-style-content">
                                    <h3 className="custom-style-title">{style.name}</h3>
                                    <p className="custom-style-description">{style.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <div className='panel' style={{marginTop:'30px', width:'auto'}}>
                    <h2 style={{margin:'0'}}>Unlock custom styles</h2>
                    <p>Upgrade now to personalize your style and craft custom instructions for unique notes.</p>
                    <button className="button-important" onClick={onOpenPricing}>
                        <div className="inner">
                            <span className="material-symbols-outlined icon">star</span>
                            Upgrade to Echonote Plus
                        </div>
                    </button>
                    <p style={{ marginTop:"15px", color:"#808080"}} className="info-text">Plus can be used to make longer recordings and more. <a href="#" onClick={onOpenPricing}>More info here!</a></p>
                </div>
            )}

            <h2>Built-in styles</h2>
            <p>Fine-tuned style provided by Echonote</p>
            <div className="custom-style-container">
                {builtInStyles.map((style) => (
                    <div className="builtin-style-card" key={style.id}>
                        <div className="builtin-style-icon">
                            <span className="material-symbols-outlined icon">{style.icon}</span>
                        </div>
                        <div className="builtin-style-content">
                            <h3 className="builtin-style-title">{style.name}</h3>
                            <p className="builtin-style-description">{style.description}</p>
                        </div>
                    </div>
                ))}
            </div>

            {isFormOpen && (
                <CustomStyleForm
                    onClose={closeForm}
                    existingStyle={currentStyle}
                />
            )}
        </div>
    );
};

export default CustomStyle;
