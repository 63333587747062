// components/TodoList.js
import React, { useState, useEffect, useRef } from 'react';
import './Todolist.css';
import api from '../api';

const TodoList = ({ todolistId, onBack }) => {
    const [todoList, setTodoList] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [title, setTitle] = useState('');
    const [tasks, setTasks] = useState([]);
    const titleRef = useRef(null);
    const taskRefs = useRef([]);

    useEffect(() => {
        const fetchTodoList = async () => {
            const response = await api.get(`/fetch_todolist/${todolistId}`);
            if (response.data.result === "OK") {
                setTodoList(response.data.todolist);
                setTitle(response.data.todolist.name);
                setTasks(response.data.todolist.tasks);

                setTimeout(() => {
                    if (titleRef.current) autoResize(titleRef.current);
                    taskRefs.current.forEach((ref) => {
                        if (ref) autoResize(ref);
                    });
                }, 100);
            }
        };
        fetchTodoList();
    }, [todolistId]);

    useEffect(() => {
        // Auto-resize after fetching the data to ensure proper height on first render
        if (titleRef.current) autoResize(titleRef.current);
        taskRefs.current.forEach((ref) => {
            if (ref) autoResize(ref);
        });
    }, [title, tasks]); // Trigger the resize when title and tasks change

    const handleInput = (setter, ref) => (e) => {
        setter(e.target.value);
        autoResize(ref.current);
    };

    const toggleEdit = () => setIsEditing(!isEditing);

    const saveTodoList = async () => {
        await api.post('/update_todolist', {
            id: todolistId,
            name: title,
            tasks: tasks.map((task) => ({
                id: task.id,
                description: task.text,
                completed: task.check // Sauvegarder le statut des tâches
            }))
        });
        setIsEditing(false);
    };

    const deleteTodoList = async () => {
        await api.delete(`/todolists/${todolistId}`);
        onBack();
    };

    const addTask = () => {
        setTasks([...tasks, { id: Date.now(), text: 'New Task', check: false }]);
    };

    const updateTask = (index, text) => {
        const newTasks = [...tasks];
        newTasks[index].text = text;
        setTasks(newTasks);
    };

    const toggleTask = (index) => {
        const newTasks = [...tasks];
        newTasks[index].check = !newTasks[index].check;
        setTasks(newTasks);
    };

    const saveTaskCheck = async (taskId, completed) => {
        await api.post('/toggle_task', {
            id: taskId,
            completed
        });
    };

    const handleCheck = (index, taskId) => {
        toggleTask(index);
        saveTaskCheck(taskId, tasks[index].check);
    };

    const autoResize = (element) => {
        element.style.height = 'auto'; // Réinitialise la hauteur
        element.style.height = `${element.scrollHeight}px`; // Ajuste à la hauteur du contenu
    };

    return (
        <div className="todolist-section">
            <div className="todolist-header">
                <button className="button-plain" onClick={onBack}>
                    <span className="material-symbols-outlined">arrow_back</span>
                </button>
                <button className="button-plain" onClick={deleteTodoList}>
                    <span className="material-symbols-outlined">delete</span> Delete
                </button>
            </div>
            {todoList && (
                <div className="todo-container">
                    <div className="todolist-header">
                        <textarea
                            className="title"
                            value={title}
                            onChange={handleInput(setTitle, titleRef)}
                            disabled={!isEditing}
                            ref={titleRef}
                            style={{ resize: 'none', overflow: 'hidden' }}
                        />
                        <button className="button-plain" onClick={isEditing ? saveTodoList : toggleEdit}>
                            <span className="material-symbols-outlined">{isEditing ? 'save' : 'edit'}</span>
                        </button>
                    </div>
                    <div className="tasks-list">
                        {tasks.map((task, index) => (
                            <div key={task.id} className="task-item">
                                {/* Checkbox activé uniquement lorsque l'édition est désactivée */}
                                <input
                                    type="checkbox"
                                    checked={task.check}
                                    onChange={() => handleCheck(index, task.id)}
                                    disabled={isEditing} // Désactivé en mode édition
                                />
                                <input
                                    type="text"
                                    className="task-input"
                                    value={task.text}
                                    onChange={(e) => updateTask(index, e.target.value)}
                                    disabled={!isEditing} // Editable seulement en mode édition
                                    ref={(el) => (taskRefs.current[index] = el)}
                                    rows={1}
                                />
                            </div>
                        ))}
                    </div>
                    {isEditing && (
                        <button className="button-plain" onClick={addTask} style={{marginTop:'30px'}}>
                            <span className="material-symbols-outlined">add</span> Add Task
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default TodoList;
