import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown'; // Importation de la bibliothèque pour afficher le Markdown
import './Note.css';
import api from '../api';
import { generateTagColor } from '../../utils/colorUtils'; // Ajout de l'import comme dans Notes.js

const Note = ({ noteId, onBack }) => {
    const [note, setNote] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [tags, setTags] = useState([]);
    const titleRef = useRef(null);
    const contentRef = useRef(null);

    useEffect(() => {
        const fetchNote = async () => {
            const response = await api.get(`/fetch_note/${noteId}`);
            if (response.data.result === "OK") {
                setNote(response.data.note);
                setTitle(response.data.note.name);
                setContent(response.data.note.text);
                setTags(response.data.note.tags || []);

                setTimeout(() => {
                    if (titleRef.current) autoResize(titleRef.current);
                    if (contentRef.current) autoResize(contentRef.current);
                }, 100);
            }
        };
        fetchNote();
    }, [noteId]);

    const handleInput = (setter, ref) => (e) => {
        setter(e.target.value);
        autoResize(ref.current); // Appelle autoResize pour ajuster la hauteur
    };

    const toggleEdit = async () => {
        setIsEditing(!isEditing);
    
        // Ajoute un délai de 100ms avant de redimensionner
        await new Promise((resolve) => setTimeout(resolve, 100));
    
        if (contentRef.current) autoResize(contentRef.current);
        if (titleRef.current) autoResize(titleRef.current);
    };

    const saveNote = async () => {
        await api.post('/update_note', { id: noteId, name: title, text: content });
        setIsEditing(false);
    };

    const deleteNote = async () => {
        await api.delete(`/notes/${noteId}`);
        onBack();
    };

    const handleRemoveTag = async (tagId) => {
        try {
            await api.delete(`/notes/${noteId}/tags/${tagId}`);
            setTags(tags.filter(tag => tag.id !== tagId));
        } catch (error) {
            console.error('Erreur lors de la suppression du tag:', error);
        }
    };

    const autoResize = (element) => {
        element.style.height = 'auto'; // Réinitialise la hauteur
        element.style.height = `${element.scrollHeight}px`; // Ajuste à la hauteur du contenu
    };

    // Ajoute l'ajustement automatique de la taille quand le composant est monté
    useEffect(() => {
        if (titleRef.current) autoResize(titleRef.current);
        if (contentRef.current) autoResize(contentRef.current);
    }, [title, content]);

    return (
        <div className="note-section">
            <div className="note-header">
                <button className="button-plain" onClick={onBack}>
                    <span className="material-symbols-outlined">arrow_back</span>
                </button>
                <button className="button-plain" onClick={deleteNote}>
                    <span className="material-symbols-outlined">delete</span> Delete
                </button>
            </div>
            {note && (
                <div className="note-container">
                    <div className="note-header">
                        <textarea
                            className="title"
                            value={title}
                            onChange={handleInput(setTitle, titleRef)}
                            disabled={!isEditing}
                            ref={titleRef}
                            style={{ resize: 'none', overflow: 'hidden' }} // Désactive le redimensionnement manuel
                        />
                        <button className="button-plain" onClick={isEditing ? saveNote : toggleEdit}>
                            <span className="material-symbols-outlined">
                                {isEditing ? 'save' : 'edit'}
                            </span>
                        </button>
                    </div>
                    <div className="note-tags">
                        {tags.map(tag => (
                            <span 
                                key={tag.id} 
                                className="tag"
                                style={{ backgroundColor: generateTagColor(tag.name) }}
                            >
                                {tag.name}
                                <button 
                                    className="tag-remove"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveTag(tag.id);
                                    }}
                                >
                                    <span className="material-symbols-outlined">close</span>
                                </button>
                            </span>
                        ))}
                    </div>
                    <div className="note-content">
                        <textarea
                            className="content"
                            value={content}
                            onChange={handleInput(setContent, contentRef)}
                            disabled={!isEditing}
                            ref={contentRef}
                            style={{ resize: 'none', overflow: 'hidden', display: isEditing ? "block" : "none" }}
                        />
                        {!isEditing && (

                            <ReactMarkdown className="markdown-content">{content}</ReactMarkdown>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Note;
