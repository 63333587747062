// components/CustomStyleForm.js
import React, { useState } from 'react';
import Popup from '../UI/Popup';
import './CustomStyleForm.css';
import api from '../api';

const CustomStyleForm = ({ onClose, existingStyle }) => {
    const [name, setName] = useState(existingStyle?.name || '');
    const [description, setDescription] = useState(existingStyle?.description || '');
    const [prompt, setPrompt] = useState(existingStyle?.prompt || '');

    const handleSave = async () => {
        if (existingStyle) {
            await api.put(`/custom_styles/${existingStyle.id}`, { name, description, prompt });
        } else {
            await api.post('/custom_styles', { name, description, prompt });
        }
        onClose();
    };

    const handleDelete = async () => {
        await api.delete(`/custom_styles/${existingStyle.id}`);
        onClose();
    };

    return (
        <Popup onClose={onClose}>
            <h3>{existingStyle ? 'Edit Custom Style' : 'Create Custom Style'}</h3>
            <form>
                <div className="form-group">
                    <label htmlFor="custom-name">Name</label>
                    <input
                        id="custom-name"
                        className="form-control"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="custom-description">Description</label>
                    <input
                        id="custom-description"
                        className="form-control"
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="custom-prompt">Prompt</label>
                    <textarea
                        id="custom-prompt"
                        className="form-control"
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        required
                    />
                </div>
                <div className="button-group">
                    {existingStyle && (
                        <button type="button" className="button-hollow" onClick={handleDelete}>
                            <span className="material-symbols-outlined">delete</span> Delete
                        </button>
                    )}
                    <button type="button" className="button-plain" onClick={handleSave}>
                        <span className="material-symbols-outlined">save</span> {existingStyle ? 'Save' : 'Create'}
                    </button>
                </div>
            </form>
        </Popup>
    );
};

export default CustomStyleForm;
