import React from 'react';
import './Legal.css'; // Assurez-vous d'inclure votre propre CSS

const Legal = () => {
    return (
        <div className="legal-container">
            <div className="legal-content">
                <h1>Terms and Conditions of Echonote</h1>
                <h2>Introduction</h2>
                <p>
                    Welcome to Echonote. Echonote is a Software as a Service (SaaS) operated by Fabien BOCO, a French auto-entrepreneur, located at Colomiers, 31770 France. By using our service, you agree to comply with and be bound by the following terms and conditions. Please review them carefully.
                </p>
                <h2>1. Definitions</h2>
                <p><strong>Service:</strong> Echonote, a software service that converts voice notes into written notes using AI processing.</p>
                <p><strong>User:</strong> Any individual or entity that uses the Echonote service.</p>
                <p><strong>We/Us/Our:</strong> Echonote and its owner/operator, Fabien BOCO.</p>
                <p><strong>Terms:</strong> These terms and conditions.</p>
                <h2>2. Acceptance of Terms</h2>
                <p>By accessing and using Echonote, you accept and agree to be bound by these Terms. If you do not agree to these Terms, you should not use the Service.</p>
                <h2>3. Changes to Terms</h2>
                <p>We reserve the right to modify these Terms at any time. Any changes will be posted on our website and will take effect immediately upon posting. It is your responsibility to review the Terms regularly. Continued use of the Service after any such changes constitutes your acceptance of the new Terms.</p>
                <h2>4. Description of Service</h2>
                <p>Echonote provides a service that converts voice notes into written notes using AI processing. The service is available through our website, nova-prospect.com.</p>
                <h2>5. User Responsibilities</h2>
                <p><strong>Account Management:</strong> You are responsible for managing your account, including keeping your login information secure.</p>
                <p><strong>Usage:</strong> You agree to use the Service only for lawful purposes and in accordance with these Terms.</p>
                <p><strong>Expenses:</strong> You are responsible for all expenses incurred through your use of the Service.</p>
                <h2>6. Payments</h2>
                <p><strong>Billing:</strong> Payments are processed through Stripe. You agree to provide accurate and complete billing information.</p>
                <p><strong>No Refunds:</strong> All payments are non-refundable, except in cases of technical issues that prevent you from using the Service.</p>
                <h2>7. Data Privacy and Security</h2>
                <p><strong>Confidentiality:</strong> All data processed by Echonote is confidential and protected.</p>
                <p><strong>Developer Access:</strong> Developers may access data for debugging or support purposes.</p>
                <p><strong>Data Protection:</strong> We take reasonable measures to protect your data from unauthorized access or disclosure.</p>
                <h2>8. Termination of Service</h2>
                <p>We reserve the right to terminate the Service at any time, for any reason, without notice. We also reserve the right to transfer or sell the business, in which case these Terms will continue to apply to the new owner/operator.</p>
                <h2>9. Limitation of Liability</h2>
                <p>Echonote is provided "as is" and "as available" without any warranties of any kind. We do not guarantee that the Service will be uninterrupted, secure, or error-free. To the maximum extent permitted by law, we are not liable for any direct, indirect, incidental, or consequential damages arising from your use of the Service.</p>
                <h2>10. Governing Law</h2>
                <p>These Terms are governed by and construed in accordance with the laws of France. Any disputes arising from or relating to these Terms or your use of the Service will be subject to the exclusive jurisdiction of the courts of France.</p>
                <h2>11. Contact Information</h2>
                <p>If you have any questions about these Terms, please contact us at <a href="mailto:contact@echonote.xyz">contact@echonote.xyz</a>.</p>

                <h1>Privacy Policy of Echonote</h1>
                <h2>Introduction</h2>
                <p>This Privacy Policy explains how we collect, use, disclose, and protect your information when you use Echonote. By using the Service, you agree to the collection and use of information in accordance with this policy.</p>
                <h2>1. Information Collection</h2>
                <p>We collect information that you provide directly to us, such as when you create an account, upload voice notes, or communicate with us. The types of information we may collect include:</p>
                <p><strong>Personal Information:</strong> Name, email address, and payment information.</p>
                <p><strong>Voice Data:</strong> Voice notes that you upload to the Service.</p>
                <p><strong>Usage Data:</strong> Information about how you use the Service.</p>
                <h2>2. Use of Information</h2>
                <p>We use the information we collect to:</p>
                <ul>
                    <li>Provide, operate, and maintain the Service.</li>
                    <li>Improve, personalize, and expand the Service.</li>
                    <li>Understand and analyze how you use the Service.</li>
                    <li>Process transactions and send related information.</li>
                    <li>Communicate with you, either directly or through one of our partners.</li>
                </ul>
                <h2>3. Disclosure of Information</h2>
                <p>We may share the information we collect with third parties in the following situations:</p>
                <p><strong>With Service Providers:</strong> We may share your information with third-party service providers to perform functions and provide services to us.</p>
                <p><strong>For Legal Reasons:</strong> We may disclose your information if required by law or in response to valid requests by public authorities.</p>
                <h2>4. Data Security</h2>
                <p>We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the internet or method of electronic storage is 100% secure, so we cannot guarantee absolute security.</p>
                <h2>5. Data Retention</h2>
                <p>We will retain your information for as long as your account is active or as needed to provide you with the Service. We may also retain and use your information to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
                <h2>6. Your Data Protection Rights</h2>
                <p>You have the right to access, correct, update, or delete your personal information. To exercise these rights, please contact us at <a href="mailto:contact@echonote.xyz">contact@echonote.xyz</a>.</p>
                <h2>7. Changes to This Privacy Policy</h2>
                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.</p>
                <h2>8. Contact Us</h2>
                <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:contact@echonote.xyz">contact@echonote.xyz</a>.</p>
            </div>
        </div>
    );
};

export default Legal;
