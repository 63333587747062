// LandingPage.js
import React, { useState } from 'react';
import './LandingPage.css'
import { Link } from 'react-router-dom';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import Navbar from './Navbar';
import Footer from './Footer';

const LandingPage = () => {

    const [openIndex, setOpenIndex] = useState(null);

    const togglePanel = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (

        <ParallaxProvider>
            
            <Navbar/>

            <Parallax className='parallax-bg' speed={-30}>
                <img src="/background.png" alt="background image" className="parallax-image" />
            </Parallax>

            <Parallax className='landing-page'>

                <div className="section hero-section">
                    <div className="hero-content">
                        <h1><span className="highlight">Speak</span> Your Thoughts, <span className="highlight">Organize</span> Your Ideas</h1>
                        <h4>From voice to clarity in seconds</h4>
                        <p>
                            Echonote is designed to save you time and enhance your productivity by turning your spoken words into well-organized, actionable items.
                        </p>

                        <Link to="/signup" className="no-style-link">
                            <button className="button-important">
                                <div className="inner">
                                    <span className="material-symbols-outlined icon">note</span>
                                    Try Echonote for free now
                                </div>
                            </button>
                        </Link>

                    </div>

                    <div className="hero-image">
                        <img src="animation.gif" alt="Hero image" />
                    </div>
                </div>


                <div class="section features-section" id="features">
                    <div class="section-header">
                        <h4>FEATURES</h4>
                        <h2>Discover Echonote’s <span class="highlight">Powerful Features</span></h2>
                        <p>
                            From accurate transcriptions to custom styles, Echonote provides everything you need to efficiently manage your voice notes.
                        </p>
                    </div>

                    <div class="features-grid">
                        <div class="feature-item">
                            <div class="icon">
                                <span class="material-symbols-outlined">shield</span>
                            </div>
                            <div>
                                <h4>Structured Notes</h4>
                                <p>Convert your voice recordings into neatly organized and structured notes. Perfect for keeping your thoughts clear and accessible.</p>
                            </div>
                        </div>

                        <div class="feature-item">
                            <div class="icon">
                                <span class="material-symbols-outlined">checklist</span>
                            </div>
                            <div>
                                <h4>To-Do Lists</h4>
                                <p>Transform your thoughts into actionable to-do lists to boost your productivity. Echonote helps you stay on top of your tasks effortlessly.</p>
                            </div>
                        </div>

                        <div class="feature-item">
                            <div class="icon">
                                <span class="material-symbols-outlined">palette</span>
                            </div>
                            <div>
                                <h4>Custom Styles</h4>
                                <p>Personalize your transcriptions with custom prompts to generate various types of content tailored to your needs. From blog posts to detailed reports, your creativity is the only limit.</p>
                            </div>
                        </div>

                        <div class="feature-item">
                            <div class="icon">
                                <span class="material-symbols-outlined">devices</span>
                            </div>
                            <div>
                                <h4>Multi-Platform Availability</h4>
                                <p>Use Echonote on Web, iOS, and Android to ensure you can capture and organize your thoughts seamlessly across all your devices.</p>
                            </div>
                        </div>
                    </div>

                </div>


                <div className="section steps-section" id="steps">
                    <div className="image-container">
                        <img src="recorder.jpg" alt="Description de l'image" className="laptop-image" />
                    </div>
                    <div className="steps-content">
                        <div className='section-header'>
                            <h4>STEPS</h4>
                            <h2>
                                Simple and <span className="highlight">Efficient</span> Process
                            </h2>
                            <p>Transforming your voice notes into actionable content is as easy as four simple steps.</p>
                        </div>
                        <div className="step">
                            <h3 className="step-number">01</h3>
                            <div className="step-details">
                                <h4 className="step-title">Speak Freely</h4>
                                <p className="step-description">Capture your thoughts effortlessly with Echonote.</p>
                            </div>
                        </div>
                        <div className="step">
                            <h3 className="step-number">02</h3>
                            <div className="step-details">
                                <h4 className="step-title"> Behind-the-Scenes Magic</h4>
                                <p className="step-description">
                                    Our AI processes your recording swiftly in the background.
                                </p>
                            </div>
                        </div>
                        <div className="step">
                            <h3 className="step-number">03</h3>
                            <div className="step-details">
                                <h4 className="step-title">Tailor-Ready Notes</h4>
                                <p className="step-description">
                                    Receive your transcription in your preferred format—be it structured notes or a custom style at your command.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="section faq-section" id="faq">
                    <div class="section-header">
                        <h4 class="faq-label">FAQ</h4>
                        <h2 class="faq-title">Discover <span class="highlight">Frequently Asked Questions</span></h2>
                        <p class="faq-subtitle">
                            From accurate transcriptions to custom styles, Echonote provides everything you need to efficiently manage your voice notes.
                        </p>
                    </div>

                    <div class="faq-grid">

                        <div className="faq-item">
                            <div className="faq-question" onClick={() => togglePanel(0)}>
                                <span>What is EchoNote App and why should I use it?</span>
                                <span className="material-symbols-outlined icon">
                                    {openIndex === 0 ? 'expand_circle_up' : 'expand_circle_down'}
                                </span>
                            </div>
                            {openIndex === 0 && (
                                <div className="faq-answer">
                                    EchoNote App is a cutting-edge tool designed for anyone who needs to record, transcribe, and manage notes efficiently. Whether you're a student, professional, or creative, EchoNote simplifies the process of capturing and organizing your ideas, tasks, and important information. With features like high-quality voice recording, automatic transcription, and customizable styles, EchoNote ensures you stay productive and organized.
                                </div>
                            )}
                        </div>

                        <div className="faq-item">
                            <div className="faq-question" onClick={() => togglePanel(1)}>
                                <span>How does EchoNote improve my productivity?</span>
                                <span className="material-symbols-outlined icon">
                                    {openIndex === 1 ? 'expand_circle_up' : 'expand_circle_down'}
                                </span>
                            </div>
                            {openIndex === 1 && (
                                <div className="faq-answer">
                                    EchoNote streamlines your workflow by allowing you to capture thoughts and tasks quickly through voice recordings. It automatically transcribes your recordings into written notes or to-do lists, saving you time and effort. With customizable styles and easy editing features, you can format your notes to fit your preferences, ensuring that your information is always clear and actionable.
                                </div>
                            )}
                        </div>

                        <div className="faq-item">
                            <div className="faq-question" onClick={() => togglePanel(2)}>
                                <span>What makes EchoNote different from other note-taking apps?</span>
                                <span className="material-symbols-outlined icon">
                                    {openIndex === 2 ? 'expand_circle_up' : 'expand_circle_down'}
                                </span>
                            </div>
                            {openIndex === 2 && (
                                <div className="faq-answer">
                                    EchoNote stands out with its unique combination of voice recording, automatic transcription, and customizable note styles. Unlike traditional note-taking apps, EchoNote offers a seamless way to turn spoken words into organized, editable text. Our app is also designed to be intuitive and user-friendly, ensuring that you can focus on your content without getting bogged down by complicated features.
                                </div>
                            )}
                        </div>

                        <div className="faq-item">
                            <div className="faq-question" onClick={() => togglePanel(3)}>
                                <span>Can I trust EchoNote with my data?</span>
                                <span className="material-symbols-outlined icon">
                                    {openIndex === 3 ? 'expand_circle_up' : 'expand_circle_down'}
                                </span>
                            </div>
                            {openIndex === 3 && (
                                <div className="faq-answer">
                                    Absolutely. Data security is a top priority at EchoNote. We employ industry-standard encryption and security practices to protect your notes and personal information. Your data is stored securely, and we do not share it with third parties without your explicit consent. You can use EchoNote with confidence, knowing that your information is safe.
                                </div>
                            )}
                        </div>

                        <div className="faq-item">
                            <div className="faq-question" onClick={() => togglePanel(4)}>
                                <span>How does EchoNote help with organization and task management?</span>
                                <span className="material-symbols-outlined icon">
                                    {openIndex === 4 ? 'expand_circle_up' : 'expand_circle_down'}
                                </span>
                            </div>
                            {openIndex === 4 && (
                                <div className="faq-answer">
                                    EchoNote allows you to create and manage notes and to-do lists effortlessly. You can record voice notes on the go, which are automatically transcribed and organized within the app. Customizable styles help you format your notes to suit your needs, and the ability to edit, delete, and manage tasks ensures that you stay on top of your responsibilities.
                                </div>
                            )}
                        </div>

                        <div className="faq-item">
                            <div className="faq-question" onClick={() => togglePanel(5)}>
                                <span>Is EchoNote easy to use?</span>
                                <span className="material-symbols-outlined icon">
                                    {openIndex === 5 ? 'expand_circle_up' : 'expand_circle_down'}
                                </span>
                            </div>
                            {openIndex === 5 && (
                                <div className="faq-answer">
                                    Yes, EchoNote is designed with user experience in mind. The interface is intuitive and straightforward, making it easy for anyone to start recording and managing notes immediately. Whether you're tech-savvy or not, you'll find EchoNote easy to navigate and use effectively.
                                </div>
                            )}
                        </div>

                        <div className="faq-item">
                            <div className="faq-question" onClick={() => togglePanel(6)}>
                                <span>Can I use EchoNote on multiple devices?</span>
                                <span className="material-symbols-outlined icon">
                                    {openIndex === 6 ? 'expand_circle_up' : 'expand_circle_down'}
                                </span>
                            </div>
                            {openIndex === 6 && (
                                <div className="faq-answer">
                                    Yes, EchoNote is fully responsive and works seamlessly across desktop and mobile devices. This means you can access and manage your notes whether you're at your desk or on the go, ensuring that your important information is always within reach.
                                </div>
                            )}
                        </div>

                        <div className="faq-item">
                            <div className="faq-question" onClick={() => togglePanel(7)}>
                                <span>How does the trial version of EchoNote work?</span>
                                <span className="material-symbols-outlined icon">
                                    {openIndex === 7 ? 'expand_circle_up' : 'expand_circle_down'}
                                </span>
                            </div>
                            {openIndex === 7 && (
                                <div className="faq-answer">
                                    EchoNote offers a trial version that provides full access to all features for a limited time. This allows you to explore the app and experience its benefits without any commitment. To start your trial, simply sign up and select the trial option during registration. We believe you'll love EchoNote and the productivity boost it provides.
                                </div>
                            )}
                        </div>

                        <div className="faq-item">
                            <div className="faq-question" onClick={() => togglePanel(8)}>
                                <span>What if I need help or support?</span>
                                <span className="material-symbols-outlined icon">
                                    {openIndex === 8 ? 'expand_circle_up' : 'expand_circle_down'}
                                </span>
                            </div>
                            {openIndex === 8 && (
                                <div className="faq-answer">
                                    Our support team is always here to help you. If you encounter any issues or have questions, you can contact us at support@echonote.com. Additionally, our Help Center provides comprehensive guides and answers to common questions, ensuring you have all the information you need to make the most of EchoNote.
                                </div>
                            )}
                        </div>

                        <div className="faq-item">
                            <div className="faq-question" onClick={() => togglePanel(9)}>
                                <span>Can EchoNote really handle all my note-taking needs?</span>
                                <span className="material-symbols-outlined icon">
                                    {openIndex === 9 ? 'expand_circle_up' : 'expand_circle_down'}
                                </span>
                            </div>
                            {openIndex === 9 && (
                                <div className="faq-answer">
                                    Absolutely. EchoNote is designed to be your all-in-one solution for recording, transcribing, and managing notes. With features tailored to enhance productivity and organization, EchoNote can handle everything from simple to-do lists to complex project notes. Try it out and see the difference it makes in your daily routine.
                                </div>
                            )}
                        </div>

                        <div className="faq-item">
                            <div className="faq-question" onClick={() => togglePanel(10)}>
                                <span>How customizable is EchoNote?</span>
                                <span className="material-symbols-outlined icon">
                                    {openIndex === 10 ? 'expand_circle_up' : 'expand_circle_down'}
                                </span>
                            </div>
                            {openIndex === 10 && (
                                <div className="faq-answer">
                                    EchoNote offers a high degree of customization to fit your personal and professional needs. You can create and edit custom note styles, ensuring that your notes look exactly the way you want them to. This flexibility allows you to tailor the app to match your workflow, making it an indispensable tool for productivity.
                                </div>
                            )}
                        </div>

                        <div className="faq-item">
                            <div className="faq-question" onClick={() => togglePanel(11)}>
                                <span>Is EchoNote suitable for collaborative work?</span>
                                <span className="material-symbols-outlined icon">
                                    {openIndex === 11 ? 'expand_circle_up' : 'expand_circle_down'}
                                </span>
                            </div>
                            {openIndex === 11 && (
                                <div className="faq-answer">
                                    While EchoNote is primarily designed for individual use, it provides a solid foundation for capturing and organizing information that can easily be shared with others. Export your notes and to-do lists to share them with colleagues, classmates, or team members, enhancing collaboration and ensuring everyone stays on the same page.
                                </div>
                            )}
                        </div>

                        <div className="faq-item">
                            <div className="faq-question" onClick={() => togglePanel(12)}>
                                <span>How often is EchoNote updated?</span>
                                <span className="material-symbols-outlined icon">
                                    {openIndex === 12 ? 'expand_circle_up' : 'expand_circle_down'}
                                </span>
                            </div>
                            {openIndex === 12 && (
                                <div className="faq-answer">
                                    We are committed to continuously improving EchoNote. Our team regularly releases updates that include new features, enhancements, and security improvements. We listen to user feedback to ensure that EchoNote evolves to meet your needs and remains a cutting-edge productivity tool.
                                </div>
                            )}
                        </div>

                        <div className="faq-item">
                            <div className="faq-question" onClick={() => togglePanel(13)}>
                                <span>What are the benefits of using voice recordings in EchoNote?</span>
                                <span className="material-symbols-outlined icon">
                                    {openIndex === 13 ? 'expand_circle_up' : 'expand_circle_down'}
                                </span>
                            </div>
                            {openIndex === 13 && (
                                <div className="faq-answer">
                                    Voice recordings allow you to capture thoughts and ideas quickly, especially when you're on the go or don't have time to type. EchoNote's high-quality recording and transcription capabilities ensure that your spoken words are accurately converted into text, making it easy to review and act on your ideas later.
                                </div>
                            )}
                        </div>

                        <div className="faq-item">
                            <div className="faq-question" onClick={() => togglePanel(14)}>
                                <span>Can EchoNote help me stay organized across different projects?</span>
                                <span className="material-symbols-outlined icon">
                                    {openIndex === 14 ? 'expand_circle_up' : 'expand_circle_down'}
                                </span>
                            </div>
                            {openIndex === 14 && (
                                <div className="faq-answer">
                                    Yes, EchoNote is perfect for managing notes across various projects. You can create separate notes and to-do lists for different projects, customize styles for easy identification, and use the app's organizational features to keep everything in order. EchoNote helps you stay focused and efficient, no matter how many projects you're juggling.
                                </div>
                            )}
                        </div>
                    </div>
                    


                </div>
                
                <Footer />
            </Parallax>
        </ParallaxProvider>
    );
};

export default LandingPage;
