import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import api from '../api';
import Navbar from '../Page/Navbar';
import Footer from '../Page/Footer';
import './TranscribeToText.css';

const TranscribeToText = () => {
    const [file, setFile] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile && droppedFile.type.includes('audio')) {
            setFile(droppedFile);
            setError(null);
        } else {
            setError('Please upload an audio file');
        }
    };

    const handleFileInput = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (!selectedFile.type.includes('audio')) {
                setError('Invalid file format. Please upload an audio file.');
            } else if (selectedFile.size > 5 * 1024 * 1024) {
                setError('File size exceeds 5MB limit.');
            } else {
                setFile(selectedFile);
                setError(null);
            }
        }
    };

    const clearFile = () => {
        setFile(null);
        setError(null);
    };

    const handleSubmit = async () => {
        if (!file) return;

        setIsLoading(true);
        setError(null);

        const formData = new FormData();
        formData.append('audio', file);

        try {
            const response = await api.post('/transcribe', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            setResult(response.data.text);
        } catch (err) {
            setError(err.response?.data?.error || 'An error occurred');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Helmet>
                {/* Balises de base */}
                <title>Free MP3 to Text Transcription Tool | EchoNote</title>
                <meta name="description" content="Convert your audio files to text with EchoNote's free transcription tool. Support for 50+ languages, up to 5 minutes of audio, and instant transcription." />
                <meta name="keywords" content="mp3 to text, audio transcription, voice to text, speech to text, free transcription tool" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black" />
                <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
                <link rel="apple-touch-icon" href="%PUBLIC_URL%/android-chrome-192x192.png" />

                {/* Open Graph */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://echonote.xyz/tools/transcribe-to-text/mp3" />
                <meta property="og:title" content="Free MP3 to Text Transcription Tool | EchoNote" />
                <meta property="og:description" content="Convert your audio files to text instantly. Support for 50+ languages, up to 5 minutes of audio. Free tool with privacy-first approach." />
                <meta property="og:image" content="https://echonote.xyz/images/android-chrome-512x512.png" />
                <meta property="og:site_name" content="EchoNote" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Free MP3 to Text Transcription Tool | EchoNote" />
                <meta name="twitter:description" content="Convert your audio files to text instantly. Support for 50+ languages, up to 5 minutes of audio. Free tool with privacy-first approach." />
                <meta name="twitter:image" content="https://echonote.xyz/images/android-chrome-512x512.png" />

                {/* Balises additionnelles pour le SEO */}
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://echonote.xyz/tools/transcribe-to-text/mp3" />
                <meta name="author" content="EchoNote" />
            </Helmet>

            <Navbar />

            <div className="transcribe-container">
                <h1>Transcriber MP3 to Text</h1>
                <p className="description">
                    Drop your .mp3 file (up to 5MB) to get a text transcript in a few minutes. To get started, drag your file to the box below.
                </p>

                {!result ? (
                    // Section Upload
                    <>
                        <div
                            className={`drop-zone ${isDragging ? 'dragging' : ''}`}
                            onDragOver={(e) => {
                                e.preventDefault();
                                setIsDragging(true);
                            }}
                            onDragLeave={() => setIsDragging(false)}
                            onDrop={handleDrop}
                        >
                            {file ? (
                                <div className="file-info">
                                    <p>
                                        <span className="material-symbols-outlined icon">attach_file</span>
                                        {file.name}
                                    </p>
                                </div>
                            ) : (
                                <>
                                    <span className="material-symbols-outlined icon">upload_file</span>
                                    <p>Click, or drop your file here</p>
                                    <input
                                        type="file"
                                        accept="audio/*"
                                        onChange={handleFileInput}
                                        className="file-input"
                                    />
                                </>
                            )}
                        </div>

                        <div className="buttons-container">
                            {error && <div className="error-message">{error}</div>}
                            {file && (
                                <div className="clear-file">
                                    <button className="button-hollow" onClick={clearFile}>
                                        <span className="material-symbols-outlined">close</span>
                                        Clear File
                                    </button>
                                </div>
                            )}

                            <button
                                className="button-important"
                                onClick={handleSubmit}
                                disabled={!file || isLoading}
                            >
                                <div className="inner">
                                    {isLoading ? 'Transcribing...' : 'Transcribe File'}
                                </div>
                            </button>
                        </div>
                    </>
                ) : (
                    // Section Résultat
                    <div className="result-container">
                        <div className="result-header">
                            <h2>Transcription Result</h2>
                            <button
                                className="button-plain"
                                onClick={() => navigator.clipboard.writeText(result)}
                            >
                                <span className="material-symbols-outlined">content_copy</span>
                                Copy
                            </button>
                        </div>
                        <textarea
                            value={result}
                            readOnly
                            className="transcription-result"
                        />
                        <button
                            className="button-hollow"
                            onClick={() => {
                                setResult(null);
                                setFile(null);
                            }}
                            style={{ marginTop: '1rem' }}
                        >
                            <span className="material-symbols-outlined">restart_alt</span>
                            Transcribe Another File
                        </button>
                    </div>
                )}

                <div className="features-grid">
                    <div className="feature">
                        <h3>50+ Languages</h3>
                        <p>Transcribe audio to text in over 50 languages.</p>
                    </div>
                    <div className="feature">
                        <h3>5 Minutes</h3>
                        <p>Transcribe audio clips up to 5 minutes long.</p>
                    </div>
                    <div className="feature">
                        <h3>Privacy First</h3>
                        <p>Your files are deleted after transcription.</p>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default TranscribeToText; 