import React, { createContext, useState, useEffect } from 'react';
import api from '../api';

// Crée le contexte d'authentification
export const AuthContext = createContext();

// Composant fournisseur pour AuthContext
export const AuthProvider = ({ children }) => {

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [subscription, setSubscription] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {

            console.log("Token : " + localStorage.getItem('token'));

            setIsAuthenticated(true);

            api.get('/user_info')
            .then(response => {
                setUser(response.data);
            })
            .catch(error => console.error(error));

            api.get('/get_subscription')
            .then(response => {
                setSubscription(response.data);
            })
            .catch(error => console.error(error));
        }

    }, []);

    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, user, setUser, subscription, setSubscription }}>
            {children}
        </AuthContext.Provider>
    );
};
