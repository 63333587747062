import React, { useState } from 'react';
import api from '../api';
import './Login.css'; // Utilisation du CSS de Login

const Signup = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const handleSignup = async (e) => {
        e.preventDefault();
        setIsButtonLoading(true);
        try {
            await api.post('/signup', { email });
            setMessage('Activation email sent to your email');
        } catch (error) {
            console.error('Signup error:', error);
            setMessage('Failed to send activation email');
        } finally {
            setIsButtonLoading(false);
        }
    };

    return (
        <div className="login-page"> {/* Remplacement par login-page */}
            <div className="login-container"> {/* Remplacement par login-container */}
                <img src="logo_dark.png" alt="Logo" className="login-logo" /> {/* Utilisation de login-logo */}
                <h2>Create an account</h2>
                <p>Enter your email to create an account</p>

                <form onSubmit={handleSignup}>
                    <div className="input-group"> {/* Utilisation de input-group */}
                        <div className="icon-container"> {/* Utilisation de icon-container */}
                            <span className="material-symbols-outlined login-icon">mail</span> {/* Utilisation de login-icon */}
                        </div>
                        <input
                            type="email"
                            placeholder="your@example.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="checkbox-container"> {/* Utilisation de checkbox-container */}
                        <input type="checkbox" required />
                        <label>
                            I agree to the <a href="/legal" target="_blank">Terms and Conditions</a>
                        </label>
                    </div>

                    <div style={{ marginTop: '30px' }}>
                        <button className="button-plain" type="submit" disabled={isButtonLoading}>
                            <span className="material-symbols-outlined icon">email</span>
                            {isButtonLoading ? 'Requesting...' : 'Request activation email'}
                        </button>
                    </div>
                </form>

                <p className="login-toggle-text"> {/* Utilisation de login-toggle-text */}
                    Already have an account? <strong onClick={() => window.location.href = '/login'}>Login</strong>
                </p>
                {message && <p className="login-message">{message}</p>} {/* Utilisation de login-message */}
            </div>
        </div>
    );
};

export default Signup;
