// BlogList.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navbar from '../Page/Navbar';

import "./Blog.css"

const BlogList = () => {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        // Charger les métadonnées des articles
        fetch('/articles.json')
            .then((response) => response.json())
            .then((data) => setArticles(data));
    }, []);

    function isTimestampInPast(timestamp) {
        const now = Date.now() / 1000; // Convertit le timestamp actuel en secondes
        return timestamp < now;
    }

    return (
        <>
            <Helmet>
                <title>Blog - Echonote</title>
                <meta name="description" content="Echonote - Read our latest blog posts on various topics." />
            </Helmet>

            <Navbar />

            <div className='blog-section'>
                <h1>Blog</h1>
                <div className='blog-list'>
                    {articles.map((article) => (
                        <Link to={`/blog/${article.slug}`} key={article.slug}>
                            <div className='blog-card'>
                                <div className="blog-card-image">
                                    <img src={`/images/${article.slug}.jpg`} alt={article.title} />
                                </div>
                                <div className="blog-card-info">
                                    <h2>{article.title}</h2>
                                    <p>{article.description}</p>
                                </div>
                            </div>
                        </Link>

                    ))}
                </div>

            </div>

        </>
    );
};

export default BlogList;
