// BottomMenu.js
import React from 'react';
import './BottomMenu.css';

const BottomMenu = ({ onSelect, selected }) => (
  <div className="bottom-menu">
    <button
      className={`button-bottommenu ${selected === 'record' ? 'active' : ''}`}
      onClick={() => onSelect('record')}
    >
      <span className="material-symbols-outlined icon">mic</span>
    </button>
    <button
      className={`button-bottommenu ${selected === 'notes' ? 'active' : ''}`}
      onClick={() => onSelect('notes')}
    >
      <span className="material-symbols-outlined icon">note</span>
    </button>
    <button
      className={`button-bottommenu ${selected === 'audio' ? 'active' : ''}`}
      onClick={() => onSelect('audio')}
    >
      <span className="material-symbols-outlined icon">graphic_eq</span>
    </button>
    <button
      className={`button-bottommenu ${selected === 'custom' ? 'active' : ''}`}
      onClick={() => onSelect('custom')}
    >
      <span className="material-symbols-outlined icon">brush</span>
    </button>
    <button
      className={`button-bottommenu ${selected === 'settings' ? 'active' : ''}`}
      onClick={() => onSelect('settings')}
    >
      <span className="material-symbols-outlined icon">settings</span>
    </button>
  </div>
);

export default BottomMenu;
